import * as TimeUtils from "@/store/TimeUtils";
import { DateTime } from "luxon";

export default class HourTimeFrameController {
  constructor(vueInstance) {
    this.vue = vueInstance;
  }
  onSelectedTimeFrame() {
    // always start with the last hour of whatever date is selected
    if (this.vue.isLiveData) {
      // if we're currently viewing live data, get the last hour on selected time frame
      const currentCalendarDateTime = this.vue.getCurrentCalendarDateTime();
      this.vue.selectedHour = currentCalendarDateTime.get("hour");
    } else {
      this.vue.selectedHour = 23;
    }
    this.vue.selectedRunIndex = null;
    this.vue.selectedShiftIndex = null;
    this.vue.submit();
  }
  isPreviousTimeFrameDisabled() {
    if (!this.vue.activeFactory) return true;
    const currentProductionDateTime = this.vue.getCurrentProductionDateTime();
    const minProductionDateTime = TimeUtils.getStartBusinessDateTime(
      currentProductionDateTime.minus({ months: this.vue.maxPastMonths }),
      this.vue.productionDayMinutesFromMidnight,
      this.vue.isCalendarDayBusinessDay,
    );
    const minHour = minProductionDateTime.get("hour");
    return (
      this.vue.selectedDate === minProductionDateTime.toFormat(TimeUtils.DATE_FORMAT) &&
      this.vue.selectedHour === minHour
    );
  }
  getDateLabel() {
    return `${this.vue.getDayOfWeek(this.vue.selectedDateTime)} ${this.vue.getDayAndMonth(this.vue.selectedDateTime)}`;
  }
  selectPrevious() {
    this.vue.selectedDate =
      this.vue.selectedHour > 0
        ? this.vue.selectedDate
        : this.vue.selectedDateTime.minus({ days: 1 }).toFormat(TimeUtils.DATE_FORMAT);
    this.vue.selectedHour = this.vue.selectedHour > 0 ? this.vue.selectedHour - 1 : 23;
    this.vue.submit();
  }
  selectNext() {
    this.vue.selectedDate =
      this.vue.selectedHour < 23
        ? this.vue.selectedDate
        : this.vue.selectedDateTime.plus({ days: 1 }).toFormat(TimeUtils.DATE_FORMAT);
    this.vue.selectedHour = this.vue.selectedHour < 23 ? this.vue.selectedHour + 1 : 0;
    this.vue.submit();
  }
  selectNow() {
    this.vue.selectedDateTime = TimeUtils.getBusinessDateTime(
      DateTime.now().setZone(this.vue.timezone),
      this.vue.productionDayMinutesFromMidnight,
      this.vue.isCalendarDayBusinessDay,
    );
    this.vue.selectedHour = this.vue.selectedDateTime.get("hour");
    this.vue.submit();
  }
  selectDate() {
    // submit the new date with the same hour we are viewing;
    this.vue.submit();
  }
  checkDateTime(currentDateTime) {
    const currentHour = currentDateTime.get("hour");
    if (currentHour !== this.vue.selectedHour) {
      this.vue.selectedDateTime = currentDateTime;
      this.vue.selectedHour = currentHour;
      this.vue.submit();
    }
  }
}
