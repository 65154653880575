<template>
  <v-form ref="dateForm" class="filter-form">
    <v-menu
      v-model="menuWindow"
      :disabled="isDisabled"
      :close-on-content-click="false"
      transition="slide-y-transition"
      max-width="750px"
      rounded
    >
      <template v-slot:activator="{ on, attrs }">
        <!--
          This field triggers dialog window ------------------
          -->
        <wx-text-field
          v-model="selectedDate"
          :disabled="isDisabled"
          @click:append="openMenu"
          :label="label"
          :error-messages="validationErrors"
          v-on="on"
          v-bind="attrs"
          append-icon="mdi-menu-down"
          class="mt-0 mb-0 ml-0"
          readonly
        />
      </template>

      <!--
        Popup window for selecting date ---------------------
        -->
      <v-card tag="article" class="date-picker-window wx-panel">
        <v-btn @click="closeMenu()" :title="$t('common.closeWindowHoverTitle')" class="close-window-btn" icon large>
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-date-picker
          v-model="selectedDate"
          :min="calculateMinDate()"
          :max="calculateMaxDate()"
          :disabled="isDisabled"
          :locale="datePickerLocale"
          width="300px"
          color="var(--color-primary)"
          show-adjacent-months
          no-title
          flat
        />
        <wx-btn-standard class="d-flex centered" @click="closeMenu()">
          {{ $t("datePicker.accept") }}
        </wx-btn-standard>
      </v-card>
    </v-menu>
  </v-form>
</template>

<script>
import WxTextField from "@/components/ui/WxTextField";
import { DateTime } from "luxon";
import * as TimeUtils from "@/store/TimeUtils";
import WxBtnStandard from "@/components/ui/WxBtnStandard.vue";
import { mapGetters } from "vuex";

export default {
  name: "WxSimpleCalendarDatePicker",
  components: {
    WxTextField,
    WxBtnStandard,
  },
  props: {
    isDisabled: {
      type: Boolean,
      required: false,
      default: () => false
    },
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    timezone: {
      type: String,
      required: true,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    limitPastNumberOfDays: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      menuWindow: false,
      selectedDate: this.value,
      validationErrors: this.errorMessages,
    };
  },
  watch: {
    value() {
      this.selectedDate = this.value;
    },
    errorMessages() {
      this.validationErrors = this.errorMessages;
    },
    selectedDate() {
      if (this.selectedDate) {
        this.emitDate();
      }
    },
  },
  computed: {
    ...mapGetters("user", ["language"]),
    datePickerLocale() {
      switch (this.language) {
        case "fr":
          return "fr-CA";
        case "es":
          return "es-US";
        default:
          return "en-US";
      }
    },
  },
  methods: {
    openMenu() {
      this.menuWindow = true;
    },
    closeMenu() {
      this.menuWindow = false;
    },
    calculateMinDateTime() {
      return DateTime.now()
        .setZone(this.timezone)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .minus({ days: this.limitPastNumberOfDays });
    },
    calculateMinDate() {
      return this.calculateMinDateTime().toFormat(TimeUtils.DATE_FORMAT);
    },
    calculateMaxDateTime() {
      return DateTime.now()
        .setZone(this.timezone)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    },
    calculateMaxDate() {
      return this.calculateMaxDateTime().toFormat(TimeUtils.DATE_FORMAT);
    },
    displayDate(dateTime) {
      if (dateTime) {
        let month;
        switch (dateTime.month) {
          case 1: {
            month = this.$t("common.monthAbbreviation.january");
            break;
          }
          case 2: {
            month = this.$t("common.monthAbbreviation.february");
            break;
          }
          case 3: {
            month = this.$t("common.monthAbbreviation.march");
            break;
          }
          case 4: {
            month = this.$t("common.monthAbbreviation.april");
            break;
          }
          case 5: {
            month = this.$t("common.monthAbbreviation.may");
            break;
          }
          case 6: {
            month = this.$t("common.monthAbbreviation.june");
            break;
          }
          case 7: {
            month = this.$t("common.monthAbbreviation.july");
            break;
          }
          case 8: {
            month = this.$t("common.monthAbbreviation.august");
            break;
          }
          case 9: {
            month = this.$t("common.monthAbbreviation.september");
            break;
          }
          case 10: {
            month = this.$t("common.monthAbbreviation.october");
            break;
          }
          case 11: {
            month = this.$t("common.monthAbbreviation.november");
            break;
          }
          case 12: {
            month = this.$t("common.monthAbbreviation.december");
            break;
          }
        }
        switch (this.$i18n.locale) {
          case "fr":
            return `${dateTime.day} ${month}`;
          default:
            return `${month} ${dateTime.day}`;
        }
      }
      return "";
    },
    displaySelectedDate() {
      return this.selectedDate;
    },
    emitDate() {
      this.$emit("input", this.selectedDate);
    },
  },
};
</script>

<style lang="scss" scoped>
.date-picker-window {
  width: 100%;
  min-width: 320px;
  overflow-y: hidden;

  // over-rule vuetify
  ::v-deep .v-card,
  ::v-deep .v-list,
  ::v-deep .v-picker__body {
    background-color: transparent !important;
  }

  .centered {
    margin: auto;
  }

  .close-window-btn {
    position: absolute;
    z-index: 1;
    top: calc(var(--dialog-close-offset) / 3);
    right: calc(var(--dialog-close-offset) / 3);
    cursor: pointer;
  }

  .timezone-info {
    color: var(--color-text-subtle-theme);
  }
}

.d-flex > .flex-mobile-phone-row {
  align-self: flex-end;
}

.calendar-picker {
  background-color: var(--color-primary) !important;
}
</style>
