<template>
  <v-card :wxid="$options.name" :class="{ disabled: isTileDisabled }" class="tile lot" tag="section" sf="1">
    <grouped-tile-selector-menu :tile-index="tileIndex" />
    <div class="tile-inner">
      <h3 :title="$t('tiles.lot.name')" class="primary-title lot-number">
        {{ $t("tiles.lot.name") }}
        <v-icon v-if="!isTileAuthorized()">mdi-lock</v-icon>
      </h3>
      <fragment v-if="isLotInProgress">
        <h2 class="primary-value">{{ primaryValue }}</h2>
        <p class="value-sub-text__unit count">
          {{ secondaryValue }}
          {{ unitName }}
        </p>
        <section class="status-details">
          <dl>
            <dt>{{ $t("tiles.plannedQuantity") }}</dt>
            <dd>
              {{ plannedQuantity }}
            </dd>
          </dl>
        </section>
      </fragment>
      <!-- if !isLotInProgress -->
      <fragment v-else>
        <h2 class="primary-value wrap">{{ nullCharacter }}</h2>
        <p class="value-sub-text__unit null">{{ $t("common.none") }}</p>
      </fragment>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ProductionUnitService from "@/components/productionunit/ProductionUnitService";
import GroupedTileSelectorMenu from "@/components/dashboard/tiles/GroupedTileSelectorMenu";
import TileBase from "@/components/dashboard/tiles/TileBase";
import Tiles from "@/components/Tiles";
import Helpers from "@/helpers";

export default {
  name: "TileLot",
  extends: TileBase,
  inheritAttrs: false,
  components: { GroupedTileSelectorMenu },
  data() {
    return {
      nullCharacter: "—",
    };
  },
  computed: {
    ...mapGetters("dashboard", ["currentLot", "productionUnitConvertedUnit"]),
    ...mapGetters("packages", ["activePuHasRequiredFeature"]),
    isTileDisabled() {
      return !this.isTileAuthorized();
    },
    isLotInProgress() {
      return this.currentLot !== null;
    },
    isPlannedQuantity() {
      return (
        this.currentLot !== null && this.currentLot.planned_quantity !== null && this.currentLot.planned_quantity > 0
      );
    },
    primaryValue() {
      const currentQuantity = this.currentLot ? this.currentLot.quantity : 0;
      const currentPlannedQuantity = this.currentLot ? this.currentLot.planned_quantity : 0;
      if (currentPlannedQuantity && currentPlannedQuantity > 0) {
        return Helpers.round(100 * (currentQuantity / currentPlannedQuantity), 1) + "%";
      } else {
        return "-";
      }
    },
    secondaryValue() {
      if (this.currentLot) {
        return this.currentLot.quantity ? this.currentLot.quantity : 0;
      }
      return 0;
    },
    unitName() {
      if (this.productionUnitConvertedUnit && this.isTileAuthorized()) {
        return ProductionUnitService.getUnitName(this.productionUnitConvertedUnit);
      } else {
        return "";
      }
    },
    plannedQuantity() {
      return this.currentLot.planned_quantity ? this.currentLot.planned_quantity : "—";
    },
  },
  methods: {
    isTileAuthorized() {
      return this.activePuHasRequiredFeature(Tiles.lot.requiredFeature);
    },
  },
};
</script>

<style lang="scss">
.lot-number {
  display: inline-block;
  width: calc(100% - 40px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.wrap {
  overflow-wrap: normal;
}
</style>
