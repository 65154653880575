export default class DayTimeFrameController {
  constructor(vueInstance) {
    this.vue = vueInstance;
  }
  onSelectedTimeFrame() {
    // find the first shift in the coverage that starts on or after the selected date
    // if none found, select the current work shift
    // set the date as the start date of that shift for if the user returns to PRODUCTION_DAY
    this.vue.selectedRunIndex = this.vue.getFirstCoverageIndexOnOrAfterDate();
    this.vue.selectedHour = null;
    this.vue.selectedShiftIndex = null;
  }
  isPreviousTimeFrameDisabled() {
    if (!this.vue.productionRunCoverage) return true; // disable run navigation if no coverage is provided
    return this.vue.selectedRunIndex === this.vue.productionRunCoverage.length - 1;
  }
  getDateLabel() {
    const overlappingInfo = this.vue.coverageOverlappingInfo;
    if (!overlappingInfo) return "";
    if (overlappingInfo.isOverlapping) {
      const from = `${this.vue.getDayOfWeek(overlappingInfo.startDate)} ${this.vue.getDayAndMonth(
        overlappingInfo.startDate,
      )}`;
      const to = `${this.vue.getDayOfWeek(overlappingInfo.endDate)} ${this.vue.getDayAndMonth(
        overlappingInfo.endDate,
      )}`;
      return `${from} - ${to}`;
    } else {
      return `${this.vue.getDayOfWeek(overlappingInfo.startDate)} ${this.vue.getDayAndMonth(
        overlappingInfo.startDate,
      )}`;
    }
  }
  selectPrevious() {
    // run coverage is sorted desc (aka. current production run is at index 0)
    // to go to a previous production run, we need to increase the index
    if (!this.vue.productionRunCoverage || this.vue.selectedRunIndex >= this.vue.productionRunCoverage.length - 1) return;
    this.vue.selectedRunIndex = this.vue.selectedRunIndex + 1;
  }
  selectNext() {
    // run coverage is sorted desc (aka. current production run is at index 0)
    // to go to a previous production run, we need to decrease the index
    if (this.vue.selectedRunIndex === 0) return;
    this.vue.selectedRunIndex = this.vue.selectedRunIndex - 1;
  }
  selectNow() {
    if (!this.vue.productionRunCoverage) return; // navigation disabled if no production run coverage provided
    this.vue.selectedRunIndex = 0;
  }
  selectDate() {
    this.vue.selectedRunIndex = this.vue.getFirstCoverageIndexOnOrAfterDate();
  }
  checkDateTime() {
    // should not have to do anything since if live, the index will be 0
    // if a new production run was started, it will now appear at the first index
    // of the coverage therefore the selected production run will update
  }
}
