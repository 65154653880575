<!--
  Prop for optional slot:
    * is-display-optional-column : <template v-slot:optional-column-container>

  Optional props for inline CSS:
    * optional-col-class=""
    * first-col-class=""
    * row-vertical-spacing-class=""
-->
<template>
  <v-row class="align-center flex-small-column-gap">
    <v-col cols="12" sm="4" md="3" xl="2" style="min-width: 250px">
      <wx-select
        v-model="selectedTimeFrame"
        :items="timeFrameItems"
        :title="$t('timeNavigator.selectTimeFrame_hint')"
        class="filter-style"
        prepend-icon="$calendarItemIcon"
        hide-details
        offset-y
      />
    </v-col>
    <v-col cols="12" sm="3" md="2" lg="auto" style="min-width: 200px">
      <v-btn-toggle class="timeframe--main__date-buttons" borderless>
        <wx-btn-icon
          @click="selectPreviousTimeFrame"
          :title="$t('common.previous')"
          :disabled="isPreviousButtonDisabled"
          class="chevron"
          plain
        >
          <v-icon>mdi-chevron-left</v-icon>
        </wx-btn-icon>
        <wx-btn-standard
          @click="goToPresent()"
          :title="$t('timeNavigator.presentBtn_hint')"
          :disabled="isLiveData"
          class="text font-weight-medium"
          plain
        >
          <span>{{ $t("timeNavigator.presentBtn") }}</span>
        </wx-btn-standard>
        <wx-btn-icon
          @click="selectNextTimeFrame"
          :title="$t('common.next')"
          :disabled="isNextButtonDisabled"
          class="chevron"
          plain
        >
          <v-icon>mdi-chevron-right</v-icon>
        </wx-btn-icon>
      </v-btn-toggle>
    </v-col>
    <v-col v-if="selectedTimeFrame === 'shift' || selectedTimeFrame === 'hour'">
      <div class="d-flex align-center" v-if="selectedTimeFrame === 'shift' && workShiftCoverage">
        <!--
          Designer requested to truncate after un max of
          20 characters. I added class `text-truncate` to
          prevent minor issues on smaller breakpoints.
          - Martin Dube
          -->
        <wx-contextualized-help
          v-if="isWorkShiftNameTruncated"
          :help-card-title="$t('timeNavigator.workShiftName')"
          :help-card-activator-text="truncatedWorkShiftName"
          :max-width="320"
          color-inverted-theme-as-tooltips
          is-help-card
        >
          <template v-slot:help-card-text-slot>
            <p class="timeframe--aside__shift-name">
              {{ workShiftName }}
            </p>
          </template>
        </wx-contextualized-help>
        <p
          v-else
          :title="$t('timeNavigator.workShiftName')"
          class="timeframe--aside__shift-name cursor-help text-truncate"
        >
          {{ workShiftName }}
        </p>
      </div>
      <div class="d-flex align-center" v-if="selectedTimeFrame === 'hour'">
        <p class="timeframe--aside__shift-name cursor-help text-truncate">
          {{ selectedHourLabel }}
        </p>
      </div>
    </v-col>
    <v-col cols="12" sm="2" md="3" lg="auto" style="min-width: 175px">
      <v-menu
        v-model="isDatePickerOpen"
        :close-on-content-click="false"
        transition="slide-y-transition"
        max-width="350px"
        offset-y
        left
        nudge-bottom="4"
        rounded
      >
        <template v-slot:activator="{ on: datepickerModal, attrs }">
          <wx-text-field
            @click:append="openDatePicker"
            v-on="{ ...datepickerModal }"
            v-bind="attrs"
            :value="dateLabel"
            :title="$t('datePickerByTimeframe.changeDate')"
            :filled="false"
            :class="{ 'dates-range': coverageOverlappingInfo?.isOverlapping }"
            class="filter-style mt-0 ml-md-3 ml-lg-4"
            append-icon="mdi-menu-down"
            hide-details="true"
            single-line
            readonly
          />
        </template>
        <v-card :wxid="$options.name" tag="article" class="date-picker-window wx-panel">
          <v-btn @click="closeDatePicker()" :title="$t('common.closeWindowHoverTitle')" class="close-window-btn" icon large>
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-row>
            <v-col class="second-column pt-sm-5 d-flex flex-column">
              <v-date-picker
                v-model="selectedDate"
                :min="getMinDate()"
                :max="getMaxDate()"
                :locale="datePickerLocale"
                color="var(--color-primary)"
                width="100%"
                show-adjacent-months
                no-title
                flat
                @change="onSelectedDate"
              />
              <fieldset
                class="form-footer-actions d-flex flex-row-reverse justify-center flex-column-gap mt-3 mt-sm-5 pb-4 pb-sm-2"
              >
                <wx-btn-standard
                  @click="submit()"
                  :title="$t('dateRangePicker.applyDates')"
                  color="primary"
                  class="submit-btn flex-grow-1"
                >
                  {{ $t("dateRangePicker.applyDates") }}
                </wx-btn-standard>
              </fieldset>
            </v-col>
          </v-row>
        </v-card>
      </v-menu>
    </v-col>
    <v-col cols="12" sm="3" md="3" lg="2" style="min-width: fit-content">
      <slot name="tag-selector" />
    </v-col>
    <v-col class="d-flex flex-small-column-gap justify-end">
      <slot name="optional-column-container" />
    </v-col>
  </v-row>
</template>

<script>
import WxBtnStandard from "@/components/ui/WxBtnStandard.vue";
import WxTextField from "@/components/ui/WxTextField.vue";
import WxBtnIcon from "@/components/ui/WxBtnIcon.vue";
import WxSelect from "@/components/ui/WxSelect.vue";
import { mapActions, mapGetters } from "vuex";
import { HOUR, SHIFT, PRODUCTION_DAY, PRODUCTION_RUN } from "@/store/TimeUtils";
import * as TimeUtils from "@/store/TimeUtils";
import { DateTime } from "luxon";
import HourTimeFrameController from "@/components/ui/timeframedatepicker/HourTimeFrameController";
import ShiftTimeFrameController from "@/components/ui/timeframedatepicker/ShiftTimeFrameController";
import DayTimeFrameController from "@/components/ui/timeframedatepicker/DayTimeFrameController";
import RunTimeFrameController from "@/components/ui/timeframedatepicker/RunTimeFrameController";
import WxContextualizedHelp from "@/components/ui/WxContextualizedHelp.vue";

const DATE_TIME_INTERVAL = 10000;

export default {
  name: "WxTimeFrameDatePicker",
  components: { WxContextualizedHelp, WxSelect, WxBtnIcon, WxTextField, WxBtnStandard },
  props: {
    /**
     * Work shift and production run coverage should only be passed in the dashboard.
     * If nothing is passed, it will disable the navigation by production run and work shift.
     * AKA. The overview will only show the current production run or current work shift but won't
     * be able to navigate to previous production runs or previous work shifts.
     */
    workShiftCoverage: {
      type: Array,
      default: () => null,
    },
    productionRunCoverage: {
      type: Array,
      default: () => null,
    },
    isDisplayOptionalColumn: {
      type: Boolean,
      default: false,
    },
    optionalColClass: {
      type: String,
      default: "d-flex align-center justify-end flex-small-column-gap",
    },
    firstColClass: {
      type: String,
      default: null,
    },
    rowVerticalSpacingClass: {
      type: String,
      default: "mb-3 mb-md-0",
    },
    maxPastMonths: {
      type: Number,
      default: 1,
    },
    /**
     * This is a temporary value, that will go away once shift and production run is supported in the overview
     *
     * Until then, if this value is true, the SHIFT and PRODUCTION RUN values will be omitted from the select dropdown.
     * If SHIFT or PRODUCTION RUN is selected in the dashboard, and the user switches to the overview, then
     * the date picker will switch to PRODUCTION DAY and select the start date of the shift or production run.
     *
     * If the current shift or production run was selected, then it will select the current production day so that
     * the overview stays "live"
     *
     * TODO: Remove once SHIFT and PRODUCTION RUN is supported in the overview
     */
    isOverview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // value that controls the date picker
      isDatePickerOpen: false,

      // properties depending on the selected time frame
      selectedTimeFrame: null,
      selectedDateTime: null,
      selectedHour: null,
      selectedShiftIndex: null,
      selectedRunIndex: null,

      // interval that check if the time frame view needs to be switched
      dateTimeInterval: null,

      // time frame controllers
      hourTimeFrameController: new HourTimeFrameController(this),
      shiftTimeFrameController: new ShiftTimeFrameController(this),
      dayTimeFrameController: new DayTimeFrameController(this),
      runTimeFrameController: new RunTimeFrameController(this),
    };
  },
  watch: {
    selectedTimeFrame(newTf, oldTf) {
      if (!oldTf && newTf) return;
      this.selectedTimeFrameController.onSelectedTimeFrame();
      this.resetLoaders();
    },
    selectedWorkShift(newWs, oldWs) {
      // set the date time to the beginning of the work shift when one is selected
      if (!this.selectedWorkShift) return;
      if (oldWs && this.isObjectEquals(newWs, oldWs)) return;
      this.selectedDateTime = TimeUtils.getBusinessDateTime(
        DateTime.fromISO(this.selectedWorkShift.start_date),
        this.productionDayMinutesFromMidnight,
        this.isCalendarDayBusinessDay,
      );
      this.submit();
    },
    selectedProductionRun(newPr, oldPr) {
      // set the date time to the beginning of the production run when one is selected
      if (!this.selectedProductionRun) return;
      if (oldPr && this.isObjectEquals(newPr, oldPr)) return;
      this.selectedDateTime = TimeUtils.getBusinessDateTime(
        DateTime.fromISO(this.selectedProductionRun.start_date),
        this.productionDayMinutesFromMidnight,
        this.isCalendarDayBusinessDay,
      );
      this.submit();
    },
    isLiveData() {
      if (this.isLiveData) {
        this.dateTimeInterval = setInterval(this.checkDateTime, DATE_TIME_INTERVAL);
      } else {
        clearInterval(this.dateTimeInterval);
      }
    },
    activeFactory() {
      this.initializeData();
    },
  },
  computed: {
    ...mapGetters("user", ["language"]),
    ...mapGetters("navigation", ["timeFrame", "date", "dateData", "isLiveData", "activeFactory"]),
    selectedTimeFrameController() {
      switch (this.selectedTimeFrame) {
        case HOUR:
          return this.hourTimeFrameController;
        case SHIFT:
          return this.shiftTimeFrameController;
        case PRODUCTION_DAY:
          return this.dayTimeFrameController;
        case PRODUCTION_RUN:
          return this.runTimeFrameController;
        default:
          return this.dayTimeFrameController;
      }
    },
    timezone() {
      return this.activeFactory?.timeZone;
    },
    productionDayMinutesFromMidnight() {
      return this.activeFactory?.productionDayMinutesFromMidnight;
    },
    isCalendarDayBusinessDay() {
      return this.activeFactory?.isCalendarDayBusinessDay;
    },
    selectedDate: {
      get() {
        return this.selectedDateTime?.toFormat(TimeUtils.DATE_FORMAT);
      },
      set(value) {
        this.selectedDateTime = DateTime.fromFormat(value, TimeUtils.DATE_FORMAT);
      },
    },
    selectedWorkShift() {
      if (!this.workShiftCoverage || !this.selectedShiftIndex === null) return null;
      return this.workShiftCoverage[this.selectedShiftIndex];
    },
    selectedProductionRun() {
      if (!this.productionRunCoverage || this.selectedRunIndex === null) return null;
      return this.productionRunCoverage[this.selectedRunIndex];
    },
    datePickerLocale() {
      switch (this.language) {
        case "fr":
          return "fr-CA";
        case "es":
          return "es-US";
        default:
          return "en-US";
      }
    },
    timeFrameItems() {
      return this.isOverview
        ? [
            { text: this.$t("timeNavigator.timeFrameOption.hour"), value: HOUR },
            { text: this.$t("timeNavigator.timeFrameOption.productionDay"), value: PRODUCTION_DAY },
          ]
        : [
            { text: this.$t("timeNavigator.timeFrameOption.hour"), value: HOUR },
            { text: this.$t("timeNavigator.timeFrameOption.shift"), value: SHIFT },
            { text: this.$t("timeNavigator.timeFrameOption.productionDay"), value: PRODUCTION_DAY },
            { text: this.$t("timeNavigator.timeFrameOption.productionRun"), value: PRODUCTION_RUN },
          ];
    },
    isNextButtonDisabled() {
      // If we're viewing live data, we should not be able to next a next time frame
      return this.isLiveData;
    },
    isPreviousButtonDisabled() {
      return this.selectedTimeFrameController.isPreviousTimeFrameDisabled();
    },
    /*******************************
     * Work Shift Label
     *******************************/
    workShiftName() {
      if (this.selectedTimeFrame !== SHIFT || !this.workShiftCoverage) return null;
      if (this.workShiftCoverage && this.workShiftCoverage[this.selectedShiftIndex]) {
        return this.workShiftCoverage[this.selectedShiftIndex].work_shift_name;
      } else {
        return null;
      }
    },
    truncatedWorkShiftName() {
      return this.workShiftName ? this.workShiftName.slice(0, 19) + "..." : null;
    },
    isWorkShiftNameTruncated() {
      return this.workShiftName?.length > 20;
    },
    /*******************************
     * Date Label
     *******************************/
    dateLabel() {
      return this.selectedTimeFrameController.getDateLabel();
    },
    coverageOverlappingInfo() {
      if (this.selectedTimeFrame !== PRODUCTION_RUN || !this.selectedProductionRun) return null;

      const startDate = DateTime.fromISO(this.selectedProductionRun.start_date);
      const endDate = this.selectedProductionRun.end_date
        ? DateTime.fromISO(this.selectedProductionRun.end_date)
        : this.getCurrentCalendarDateTime();

      return {
        isOverlapping: startDate.toFormat(TimeUtils.DATE_FORMAT) !== endDate.toFormat(TimeUtils.DATE_FORMAT),
        startDate,
        endDate,
      };
    },
    selectedHourLabel() {
      if (this.isLiveData) {
        return `${this.selectedHour}:00 - ${this.$t("common.now")}`;
      } else {
        return `${this.selectedHour}:00 - ${this.selectedHour + 1}:00`;
      }
    },
  },
  methods: {
    ...mapActions("navigation", ["setTimeFrame"]),
    ...mapActions("dashboard", ["resetDashboardLoaders"]),
    ...mapActions("overview", ["resetOverviewLoaders"]),
    /*******************************
     * Utility Methods
     *******************************/
    isObjectEquals(a, b) {
      return Object.keys(a).length === Object.keys(b).length && Object.keys(a).every((p) => a[p] === b[p]);
    },
    openDatePicker() {
      this.isDatePickerOpen = true;
    },
    closeDatePicker() {
      this.isDatePickerOpen = false;
    },
    getCurrentProductionDateTime() {
      if (!this.activeFactory) return null;
      return TimeUtils.getBusinessDateTime(
        DateTime.now().setZone(this.timezone),
        this.productionDayMinutesFromMidnight,
        this.isCalendarDayBusinessDay,
      );
    },
    getCurrentCalendarDateTime() {
      if (!this.activeFactory) return null;
      return DateTime.now().setZone(this.timezone);
    },
    getMinDate() {
      return this.getCurrentProductionDateTime()
        .minus({ months: this.maxPastMonths })
        .toFormat(TimeUtils.DATE_FORMAT);
    },
    getMaxDate() {
      return this.getCurrentProductionDateTime().toFormat(TimeUtils.DATE_FORMAT);
    },
    getDayAndMonth(dateTime) {
      if (!dateTime) return "";
      let month;
      switch (dateTime.month) {
        case 1: {
          month = this.$t("common.monthAbbreviation.january");
          break;
        }
        case 2: {
          month = this.$t("common.monthAbbreviation.february");
          break;
        }
        case 3: {
          month = this.$t("common.monthAbbreviation.march");
          break;
        }
        case 4: {
          month = this.$t("common.monthAbbreviation.april");
          break;
        }
        case 5: {
          month = this.$t("common.monthAbbreviation.may");
          break;
        }
        case 6: {
          month = this.$t("common.monthAbbreviation.june");
          break;
        }
        case 7: {
          month = this.$t("common.monthAbbreviation.july");
          break;
        }
        case 8: {
          month = this.$t("common.monthAbbreviation.august");
          break;
        }
        case 9: {
          month = this.$t("common.monthAbbreviation.september");
          break;
        }
        case 10: {
          month = this.$t("common.monthAbbreviation.october");
          break;
        }
        case 11: {
          month = this.$t("common.monthAbbreviation.november");
          break;
        }
        case 12: {
          month = this.$t("common.monthAbbreviation.december");
          break;
        }
      }
      switch (this.$i18n.locale) {
        case "fr":
          return `${dateTime.day} ${month}`;
        default:
          return `${month} ${dateTime.day}`;
      }
    },
    getDayOfWeek(dateTime) {
      if (!dateTime) return "";
      // 1 is Monday and 7 is Sunday
      switch (dateTime.weekday) {
        case 1: {
          return this.$t("common.dayNamesAbbreviation.monday");
        }
        case 2: {
          return this.$t("common.dayNamesAbbreviation.tuesday");
        }
        case 3: {
          return this.$t("common.dayNamesAbbreviation.wednesday");
        }
        case 4: {
          return this.$t("common.dayNamesAbbreviation.thursday");
        }
        case 5: {
          return this.$t("common.dayNamesAbbreviation.friday");
        }
        case 6: {
          return this.$t("common.dayNamesAbbreviation.saturday");
        }
        case 7: {
          return this.$t("common.dayNamesAbbreviation.sunday");
        }
      }
    },
    getFirstCoverageIndexOnOrAfterDate() {
      let coverageList = null;
      switch (this.selectedTimeFrame) {
        case SHIFT:
          coverageList = this.workShiftCoverage;
          break;
        case PRODUCTION_RUN:
          coverageList = this.productionRunCoverage;
          break;
      }
      if (!coverageList) return null;
      let coverageIndex = 0;
      for (let i = 0; i < coverageList.length; i++) {
        const coverageStartDateTime = DateTime.fromISO(coverageList[i].start_date);
        if (coverageStartDateTime < this.selectedDateTime) break;
        coverageIndex = i;
      }
      return coverageIndex;
    },
    onSelectedDate() {
      this.selectedTimeFrameController.selectDate();
      this.resetLoaders();
    },
    /*******************************
     * Time Frame Selection Methods
     *******************************/
    selectNextTimeFrame() {
      this.selectedTimeFrameController.selectNext();
      this.resetLoaders();
    },
    selectPreviousTimeFrame() {
      this.selectedTimeFrameController.selectPrevious();
      this.resetLoaders();
    },
    goToPresent() {
      this.selectedTimeFrameController.selectNow();
      this.resetLoaders();
    },
    resetLoaders() {
      if (this.isOverview) {
        // reset overview loaders
        this.resetOverviewLoaders();
      } else {
        // reset dashboard loaders
        this.resetDashboardLoaders();
      }
    },
    checkDateTime() {
      /**
       * Check if the current view needs to be switched over to the next one. This only happens
       * if we're currently viewing live data to keep the dashboard accurate.
       *
       * Do not check if looking at past data
       * Ex.
       *    - HOUR: Check if the current hour has finished
       *    - SHIFT: Check if the current shift has finished
       *    - PRODUCTION DAY: Check if a new production day has started
       *    - PRODUCTION RUN: Check if a new production run has been started
       */

      if (!this.isLiveData) return;
      this.selectedTimeFrameController.checkDateTime(this.getCurrentProductionDateTime());
    },
    /*******************************
     * Output
     *******************************/
    submit() {
      this.emitInputs();
      this.closeDatePicker();
    },
    emitInputs() {
      const timeFrameOptions = {
        timeFrame: this.selectedTimeFrame,
        date: this.selectedDateTime,
        hour: this.selectedHour,
        shiftIndex: this.selectedShiftIndex,
        isLastShift: this.selectedShiftIndex === 0,
        runIndex: this.selectedRunIndex,
        isLastRun: this.selectedRunIndex === 0,
        selectedWorkShift: this.selectedWorkShift,
        selectedProductionRun: this.selectedProductionRun,
      };
      /**
       * Saving the navigation parameters in the store will trigger the recalculation
       * of the start and end timestamps. This allows parent components to be completely
       * ignorant of the date picker's logic. All they need to do is watch the start/end
       * timestamps and re-fetch data when they change.
       *
       * This also allows the date picker to be more easily introduced in new views
       * without a complicated integration.
       */
      this.setTimeFrame(timeFrameOptions);
    },
    initializeOverviewDatePicker() {
      /**
       * If SHIFT or PRODUCTION RUN is selected in the dashboard, and the user switches to the overview, then
       * the date picker will switch to PRODUCTION DAY and select the start date of the shift or production run.
       *
       * If the current shift or production run was selected, then it will select the current production day so that
       * the overview stays "live"
       *
       * TODO: Remove once SHIFT and PRODUCTION RUN is supported in the overview
       */
      this.selectedTimeFrame = PRODUCTION_DAY;
      if (this.isLiveData) {
        // select now to stay "live"
        this.selectedTimeFrameController.selectNow();
      } else {
        this.submit();
      }
    },
    initializeData() {
      if (!this.activeFactory) return;
      this.selectedDateTime = this.date;
      if (this.isOverview && (this.timeFrame === SHIFT || this.timeFrame === PRODUCTION_RUN)) {
        // TODO: Remove this condition once SHIFT and PRODUCTION RUN is supported in the overview
        this.initializeOverviewDatePicker();
      } else {
        this.selectedTimeFrame = this.timeFrame;
        this.selectedHour = this.dateData?.hour;
        this.selectedShiftIndex = this.dateData?.shiftIndex;
        this.selectedRunIndex = this.dateData?.runIndex;
        if (this.isLiveData) {
          this.dateTimeInterval = setInterval(this.checkDateTime, DATE_TIME_INTERVAL);
        }
        this.submit();
      }
    },
  },
  mounted() {
    this.resetLoaders();
    this.initializeData();
  },
};
</script>

<style lang="scss" scoped>

// local variables
$timeframeRow_height: 40px;
$datepickerActivator_singleDate_minWidth: 7em; // Adjust if we display more then `DD Mmm.` in the field
$datepickerActivator_2datesRange_minWidth: ($datepickerActivator_singleDate_minWidth + 8.5em);

// local extended class
.extend_focusedBackgroundPattern {
  background-image: var(--pattern-upcoming-time-sm-theme);
  border-radius: 3px;
  cursor: pointer;
}

/**
 * Form filters
 * common style
 */
.filter-form {
  fieldset.filters {
    display: flex;
    flex-basis: content;
    flex-grow: 0;
    margin: 0;
    padding: 0;
    border: none;

    // style
    ::v-deep .v-input.v-input--dense {
      .v-input__control {
        .v-input__slot {
          border-radius: var(--border-radius-form-elements);

          // hide border-bottom
          &::before,
          &::after {
            display: none;
            border-color: transparent !important;
          }
        }
      }
    }
  }
}

// v-card
.date-picker-window {
  overflow-y: hidden;

  .close-window-btn {
    position: absolute;
    z-index: 1;
    top: calc(var(--dialog-close-offset) / 3);
    right: calc(var(--dialog-close-offset) / 3);
    cursor: pointer;
  }

  // fix black background in darkMode
  ::v-deep .v-card.v-picker {
    &,
    .v-picker__body {
      background-color: transparent;
    }
  }
}

// Main responsive columns
.datepicker-col {
  &--first-column,
  &--optional-column-container {
    display: flex;
  }
  &--first-column,
  &--optional-column-container {
    ::v-deep &.col {
      margin-top: 0;
      padding-top: 0;
    }
  }
  &--optional-column-container {
    padding-bottom: 0;
  }
  &--first-column {
    align-items: stretch;
    flex-direction: column; // mobile first

    @media ($wx-isMobilePhone) {
      padding-bottom: 24px;
    }
    @media ($wx-sm-min) {
      flex-direction: row;
    }
  }
}

.timeframe {
  &--main,
  &--aside {
    align-items: center;
    justify-content: space-between;

    @media ($wx-sm-min) {
      justify-content: flex-start;
    }
  }
  // .timeframe--main
  &--main {
    flex-wrap: nowrap;
    text-align: left;

    @media ($wx-isMobilePhone) {
      flex-wrap: wrap;
    }
  }
  // .timeframe--aside
  &--aside {
    height: $timeframeRow_height;
    margin-top: -0.15rem;

    @media ($wx-isMobile) {
      padding-inline: calc(var(--grid-gutter) / 2);
    }
  }

  // .timeframe--main__selector
  &--main__selector {
    max-width: 13rem;
  }

  //  .timeframe--main__date-buttons
  &--main__date-buttons {
    &.v-btn-toggle {
      min-width: 100px;

      .v-btn.v-btn {
        &.chevron {
          min-width: 48px;
          padding-inline: 0;
        }
        // more square then the default rectangular ratio
        &.v-size--default {
          height: $timeframeRow_height;
        }

        // hacking Vuetify `plain` style to match mockup
        &.v-btn--plain {
          background-color: transparent;
          transition: var(--smooth-transition);
          transition-property: background-color;

          &:hover {
            background-color: var(--color-secondary);
          }
          ::v-deep .v-btn__content {
            opacity: 1;
          }
        }
      }
    }
  }

  @media ($wx-isMobile) {
    &--main__selector {
      max-width: 60%;
    }
    // Vertical mobilePhone ONLY
    @media ($wx-isMobilePhone) {
      &--main__selector {
        max-width: 100%;
        margin-inline: auto;
        margin-bottom: 12px;
      }
      &--main__date-buttons {
        &.v-btn-toggle {
          max-width: 100%;
          margin-inline: auto;

          .v-btn.v-btn {
            &.chevron {
              min-width: 60px;
            }
            &.text {
              min-width: 12em;
            }
          }
        }
      }
    }
  }

  // .timeframe--aside__shift-name
  &--aside__shift-name {
    display: inline-block;
    margin-bottom: 0;
    font-size: var(--font-size-norm);

    &.cursor-help {
      cursor: help;
    }
  }

  // .timeframe--aside__datepicker-activator
  &--aside__datepicker-activator {
    // Default date format is `30 Sep.`
    &:not(.dates-range) {
      min-width: $datepickerActivator_singleDate_minWidth;
      max-width: 10rem;
    }
    // If workShift is spread over more then one day: `30 Sep. - 1 Oct.`
    &.dates-range {
      min-width: $datepickerActivator_2datesRange_minWidth;
      max-width: 16rem;
    }

    @media ($wx-isMobilePhone) {
      margin-inline: auto;
    }

    ::v-deep .v-label {
      font-size: var(--font-size-norm);
      color: var(--color-text-theme);
    }
    &:hover {
      ::v-deep .v-label {
        color: var(--color-primary);
      }
    }
    ::v-deep .v-input__control {
      .v-input__append-inner {
        margin-top: 1px; // vertically align with carret from first select
      }
    }
    // ugly patch until I fix the UX
    &.v-input {
      &--is-focused ::v-deep input {
        @extend .extend_focusedBackgroundPattern;
      }
    }
    &.v-select {
      &--is-focused ::v-deep .v-select__selections {
        @extend .extend_focusedBackgroundPattern;
      }
    }
  }
}

/**
 * ui/FormFooterActions.vue
 */
.form-footer-actions {
  border-color: transparent;
  border-radius: 0;
}
</style>
