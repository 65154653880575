import http from "../../BaseServiceHttp";

class GiveawaySamplingService {
  fetchSessionInProgress(productionUnitId) {
    return http.get(`/production-units/${productionUnitId}/sampling-session-in-progress`);
  }

  fetchSessionSamples(productionUnitId, sessionId) {
    return http.get(`/production-units/${productionUnitId}/sampling-sessions/${sessionId}/samples`);
  }

  createSamplingSession(productionUnitId, httpData) {
    return http.post(`/production-units/${productionUnitId}/sampling-sessions`, httpData);
  }

  cancelSamplingSession(productionUnitId, sessionId) {
    return http.delete(`/production-units/${productionUnitId}/sampling-sessions/${sessionId}`);
  }

  completeSamplingSession(productionUnitId, sessionId) {
    return http.put(`/production-units/${productionUnitId}/sampling-sessions/${sessionId}/complete`);
  }
}

export default new GiveawaySamplingService();
