<!--
  Blocks are production time lapse values
  rendered as controls if downtime is unjustified.
  -->
<template>
  <section :wxid="$options.name" class="timeline-blocks-container">
    <template v-for="(marker, index) in shiftMarkers">
      <div
        class="shift-marker"
        :key="`shift-marker-${index}`"
        :style="{
          left: leftPosition(marker.start),
        }"
      >
      </div>
    </template>

    <template v-for="(block, index) in timeline.blocks">
      <!--   Block is down and user can justify and the duration is greater than 5 min   -->
      <button
        v-if="isBlockDown(block.state) && isJustifiableDowntime(block) && !isPresenter"
        class="block"
        :class="clickableTimelineBlockCssClass(block)"
        :key="`timeline-block-${index}`"
        @click="selectDowntime(block.downtime.id)"
        :style="{
          left: leftPosition(block.start),
          width: blockWidth(block.duration),
        }"
      >
        <template v-for="(sub_block, indexSubBlock) in block.sub_blocks">
          <span
            :class="sub_block.state"
            :key="`timeline-subblock-${indexSubBlock}-${sub_block.duration}`"
            :style="{
              width: subBlockWidth(block.duration, sub_block.duration),
            }"
          ></span>
        </template>
      </button>

      <!--   Block is down and user can justify but the duration is less than 5 min   -->
      <v-tooltip
        v-else-if="isBlockDown(block.state) && !isJustifiableDowntime(block) && !isPresenter"
        right
        allow-overflow
        offset-overflow
        :key="`timeline-unclick-block-${index}`"
        :disabled="!showUnclickableBlockTooltip(block)"
      >
        <template v-slot:activator="{ on }">
          <button
            v-if="hasSubBlocks(block)"
            v-on="on"
            class="block defaultMouseCursor"
            :class="clickableTimelineBlockCssClass(block)"
            :key="`timeline-block-${index}`"
            :style="{
              left: leftPosition(block.start),
              width: blockWidth(block.duration),
            }"
          >
            <!-- This case can happen if a downtime has been justified from a scheduled downtime
            and its duration is under the justification delay
            OR manually justified at a moment when the justification delay was lower
            -->
            <template v-for="(sub_block, indexSubBlock) in block.sub_blocks">
              <span
                :class="sub_block.state"
                :key="`timeline-subblock-${indexSubBlock}-${sub_block.duration}`"
                :style="{
                  width: subBlockWidth(block.duration, sub_block.duration),
                }"
              ></span>
            </template>
          </button>
          <div
            v-else
            v-on="on"
            class="block"
            :class="unclickableTimelineBlockCssClass(block, index)"
            :style="{
              left: leftPosition(block.start),
              width: blockWidth(block.duration),
            }"
          />
        </template>
        <div class="wx-info-window">
          <span v-if="hasSubBlocks(block)" class="text">
            {{ $t("timeline.blocks.noJustificationRequiredMinutes", { delay: formattedJustificationDelay }) }}.
            {{ $t("timeline.blocks.withSubBlockButNoJustificationRequiredMinutes") }}
          </span>
          <span v-else class="text">
            {{ $t("timeline.blocks.noJustificationRequiredMinutes", { delay: formattedJustificationDelay }) }}
          </span>
        </div>
      </v-tooltip>

      <!--   User is presenter, should not be able to click on any blocks   -->
      <button
        v-else
        class="block"
        :class="unclickableTimelineBlockCssClass(block, index)"
        :key="`timeline-block-${index}`"
        :style="{
          left: leftPosition(block.start),
          width: blockWidth(block.duration),
        }"
        disabled
      >
        <template v-for="(sub_block, indexSubBlock) in block.sub_blocks">
          <span
            :class="sub_block.state"
            :key="`timeline-subblock-${indexSubBlock}-${sub_block.duration}`"
            :style="{
              width: subBlockWidth(block.duration, sub_block.duration),
            }"
          ></span>
        </template>
      </button>
    </template>

    <div v-if="!coverageIsOneHourOrLess" class="progress-marker" :style="{ left: periodProgressPercentage }">
      <span class="d-sr-only">{{ periodProgressPercentageFloored }}</span>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "TimelineProductionBlocks",
  computed: {
    ...mapGetters("dashboard", [
      "timeline",
      "selectedDowntime",
      "selectedDowntimeId",
      "shiftMarkers",
      "isBlockDown",
      "isJustifiableDowntime",
      "currentProductionUnitDataSourceAlerts",
      "activePUJustificationDelayInSeconds",
    ]),
    ...mapGetters("user", ["isPresenter"]),
    coverageIsOneHourOrLess() {
      return this.timeline.coverageDuration <= 3600;
    },
    periodProgressPercentage() {
      return (this.timeline.coverageProgress / this.timeline.coverageDuration) * 100 + "%";
    },
    periodProgressPercentageFloored() {
      return Math.floor((this.timeline.coverageProgress / this.timeline.coverageDuration) * 100) + "%";
    },
    formattedJustificationDelay() {
      const hours = parseInt(this.activePUJustificationDelayInSeconds / 3600, 10);
      const minutes = parseInt((this.activePUJustificationDelayInSeconds % 3600) / 60, 10);
      if (hours > 0) {
        return `${hours} ${this.$t("common.hours")} ${minutes} ${this.$t("common.minutes")}`;
      } else if (minutes > 0) {
        return `${minutes} ${this.$t("common.minutes")}`;
      } else {
        // The `activePUJustificationDelayInSeconds` is under a minute
        return `${this.activePUJustificationDelayInSeconds} ${this.$t("common.seconds")}`;
      }
    },
  },
  methods: {
    ...mapActions("dashboard", ["selectDowntime"]),
    hasSubBlocks(block) {
      return block.sub_blocks && block.sub_blocks.length > 0;
    },
    showUnclickableBlockTooltip(block) {
      if (
        this.timeline &&
        this.timeline.blocks &&
        this.timeline.blocks.length > 0 &&
        this.selectedDowntimeId === null
      ) {
        return (
          block.state === "down_unplanned" ||
          block.state === "down_unjustified" ||
          block.state === "down_planned" ||
          block.state === "down_mixed"
        );
      }
      return false;
    },
    clickableTimelineBlockCssClass(block) {
      if (this.selectedDowntime) {
        if (this.isJustifiableDowntime(block)) {
          return [
            /* Based on the state of the block */ block.state,
            /* If Downtime & selected */ block.downtime.id === this.selectedDowntime.id ? "selected" : "",
          ];
        } else {
          return "hidden";
        }
      } else {
        return [
          /* Based on the state of the block */ block.state,
          /* If Downtime & selected */ this.selectedDowntime && block.downtime.id === this.selectedDowntime.id
            ? "selected"
            : "",
        ];
      }
    },
    unclickableTimelineBlockCssClass(block, index) {
      if (this.selectedDowntime) {
        return "hidden";
      } else {
        const isAlerts =
          this.currentProductionUnitDataSourceAlerts && this.currentProductionUnitDataSourceAlerts.length > 0;
        return isAlerts && index === this.timeline.blocks.length - 1 ? "connection_issues" : block.state;
      }
    },
    leftPosition(blockStart) {
      return 100 / (this.timeline.coverageDuration / blockStart) + "%";
    },
    blockWidth(blockDuration) {
      return 100 / (this.timeline.coverageDuration / blockDuration) + "%";
    },
    subBlockWidth(blockDuration, subBlockDuration) {
      return 100 / (blockDuration / subBlockDuration) + "%";
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline-blocks-container {
  $containerDefaultHeight: 40px;

  position: relative;
  flex: 1 0 auto;
  height: ($containerDefaultHeight * 2);
  background-image: var(--repeating-oblique-small-pattern);

  @media ($wx-isNotMobile) {
    height: $containerDefaultHeight;
    background-image: var(--repeating-oblique-pattern);
  }
}

// timeline.blocks template
.block {
  position: absolute;
  height: 100%;
  top: 0;
  width: 10px;
  transition: background-color 0.3s linear;

  &:focus {
    outline: none;
  }

  &.selected {
    z-index: 1;
    outline: 2px dashed var(--color-warning);
  }

  &.up {
    background-color: var(--color-uptime);
  }
  &.down_unjustified,
  .down_unjustified {
    background-color: var(--color-unjustifiedDowntime);
  }
  &.down_unplanned,
  .down_unplanned {
    background-color: var(--color-justifiedDowntime);
  }
  &.down_planned,
  .down_planned {
    background-color: var(--color-plannedDowntime);
  }
  &.out_of_production {
    background-color: var(--color-outOfProduction);
  }
  &.unknown {
    background-color: var(--color-unknown);
  }
  &.connection_issues {
    background-color: var(--color-disconnected);
  }
  &.down_mixed {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
  }
  &.hidden {
    background-color: var(--color-timeline-graph-background);
  }
}

// shiftMarkers template
.shift-marker {
  position: absolute;
  top: 0;
  width: 1px;
  height: 100%;
  z-index: 1;
  left: 50%;
  background-color: currentColor;

  &:after,
  &:before {
    content: "";
    position: absolute;
    width: 11px;
    height: 2px;
    left: -5px;
    top: 25%;
    background-color: currentColor;
  }

  &:after {
    transform: rotate(45deg);
  }

  &:before {
    transform: rotate(-45deg);
  }
}

.progress-marker {
  position: absolute;
  top: -6px;
  width: 3px;
  height: calc(100% + 17px);
  background-color: currentColor;
}

.v-tooltip__content {
  background-color: var(--color-inverted-container-theme);
}

.wx-info-window {
  max-width: 220px;
  color: var(--color-inverted-text-theme);
  background-color: var(--color-inverted-container-theme);

  .text {
    opacity: 0.9;
  }
}

.defaultMouseCursor {
  cursor: default;
}
</style>
