<template>
  <v-card
    :wxid="$options.name"
    :class="{ disabled: isTileDisabled }"
    class="tile product-speed-5m-per-minute unit-name"
    tag="section"
    sf="2"
  >
    <grouped-tile-selector-menu :tile-index="tileIndex" />
    <div class="tile-inner">
      <h3 :title="$t('tiles.productSpeed5m_unit', { unit: unitName })" class="primary-title">
        {{ $t("tiles.productSpeed5m_unit", { unit: unitName }) }}
        <v-icon v-if="!isTileAuthorized()">mdi-lock</v-icon>
      </h3>
      <h2 class="primary-value">{{ primaryValue }}</h2>
      <p class="value-sub-text__product-note">({{ $t("tiles.currentProductNote") }})</p>
      <section class="status-details">
        <dl>
          <dt>{{ targetType }}</dt>
          <dd>
            <v-icon small>mdi-target</v-icon>
            {{ targetValue }}
          </dd>
        </dl>
        <div class="pictogram" />
      </section>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ProductionUnitService from "@/components/productionunit/ProductionUnitService";
import GroupedTileSelectorMenu from "@/components/dashboard/tiles/GroupedTileSelectorMenu";
import TileBase from "@/components/dashboard/tiles/TileBase";
import Tiles from "@/components/Tiles";
import Helpers from "@/helpers";

export default {
  name: "TileProductSpeed5mPerMinute",
  extends: TileBase,
  inheritAttrs: false,
  components: { GroupedTileSelectorMenu },
  computed: {
    ...mapGetters("dashboard", [
      "productionUnitConvertedUnit",
      "currentProductSku",
      "currentProductSpeed5m",
      "currentProductPUAssociations",
      "currentProductProductionObjective",
    ]),
    ...mapGetters("packages", ["activePuHasRequiredFeature"]),
    isTileDisabled() {
      return this.deviceLatestAlert || !this.isTileAuthorized();
    },
    productSpeed5mPerMinute() {
      return Helpers.round(this.currentProductSpeed5m / 60, 0);
    },
    primaryValue() {
      return this.currentProductSpeed5m && this.isTileAuthorized() ? this.productSpeed5mPerMinute : "—";
    },
    unitName() {
      if (this.productionUnitConvertedUnit && this.isTileAuthorized()) {
        return (
          " - " + ProductionUnitService.getUnitName(this.productionUnitConvertedUnit) + this.$t("common.perMinute")
        );
      } else {
        return "";
      }
    },
    skuValue() {
      return this.currentProductSku && this.isTileAuthorized() ? this.currentProductSku : "—";
    },
    targetType() {
      return this.$t("dashboard.targetInfo.product");
    },
    target() {
      if (this.currentProductPUAssociations && this.isTileAuthorized()) {
        const objective = this.currentProductProductionObjective;
        if (objective) {
          return Helpers.round(objective.value / 60, 1); // target by hour, divided by 60
        }
      }
      return null;
    },
    targetValue() {
      return this.target ? this.target : this.$t("common.nA");
    },
  },
  methods: {
    isTileAuthorized() {
      return this.activePuHasRequiredFeature(Tiles.currentProductSpeed5mPerMinute.requiredFeature);
    },
  },
};
</script>
