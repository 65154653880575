<!--
 This component is a work around Vutify's Autocomplete component
 for touch screen devices. The goal is to prevent soft-keyboards
 to take half of the viewport when the initial field gets focus.
 So the `activator` (<button> or <select>) triggers a modal dialog,
 this give liberates space by isolating the Autocomplete field in
 the center of the available viewport.

 BOOLEAN PROPS:
   select-not-dense
 STRING PROPS:
   activator-css-class, activator-hover-title, button-color
-->
<template>
  <v-dialog
    v-model="isDialogActive"
    :fullscreen="isMobile"
    max-width="var(--modal-window-narrow-width)"
    transition="scroll-y-transition"
    wxid="JustificationReasonEntryDialog"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <wx-text-field
        ref="wxInput"
        v-bind="attrs"
        v-on="on"
        @click:append="getReasonName(internalValue)"
        :value="getReasonName(internalValue)"
        :items="reasons"
        item-value="id"
        item-text="name"
        :placeholder="callToAction"
        :title="activatorHoverTitle"
        :class="[activatorCssClass, 'v-text-field--dialog-activator', { 'field-not-dense': activatorNotDense }]"
        :rules="rules"
        :error-messages="validationErrors"
      />
    </template>

    <v-card
      :class="[{ 'is-breakpoint-mobile': isMobile }, { 'is-breakpoint-mobile--phone': isMobilePhone }]"
      class="wx-modal-dialog"
      wxid="JustificationReasonEntryDialog"
    >
      <v-card-title>
        <v-btn @click="isDialogActive = false" :title="$t('common.closeWindowHoverTitle')" class="close-btn" icon small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <h2 v-if="dialogCardTitle" class="mb-1">
          {{ dialogCardTitle }}
        </h2>
        <wx-text-field
          v-model="topFilter"
          v-if="reasons.length >= minimumLenghtToDisplayFilter"
          :placeholder="$t('justification.reasonEntry.filterReasons')"
          prepend-inner-icon="mdi-magnify"
          class="field-not-dense w-100 mt-1 mb-1"
          hide-details
          clearable
        />
        <wx-alert-query-message
          v-if="reasons.length <= 0"
          :text="$t('justification.reasonEntry.noReasonIsListed')"
          container-css-class="mt-4"
          text-css-class="mt-n1"
          type="warning"
          no-results-icon
        />
      </v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item-group v-model="internalValue" color="primary">
            <component
              v-for="(item, index) in filteredReasons"
              :is="getComponentType(item)"
              :key="`item-${index}`"
              :value="item.id"
              @click="submit(item.id)"
              :class="headerHasItems(index, filteredReasons) ? null : 'd-none'"
            >
              <fragment v-if="item.header">
                {{ item.header }}
              </fragment>
              <v-list-item-content v-if="getComponentType(item) === 'v-list-item'">
                <slot name="reason-entry-item" :item="item" />
              </v-list-item-content>
            </component>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import WxTextField from "@/components/ui/WxTextField";
import WxAlertQueryMessage from "@/components/ui/WxAlertQueryMessage.vue";

export default {
  name: "JustificationReasonEntryDialog",
  components: {
    WxTextField,
    WxAlertQueryMessage,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    justificationRow: {
      type: Object,
      required: true,
    },
    downtime: {
      type: Object,
      default: () => null,
    },
    reasons: {
      type: Array,
      default: () => [],
    },
    activatorCssClass: {
      type: String,
      default: null,
    },
    activatorHoverTitle: {
      type: String,
      default: null,
    },
    activatorLabel: {
      type: String,
      default: null,
    },
    callToAction: {
      type: String,
      default: null,
    },
    dialogCardTitle: {
      type: String,
      default: null,
    },
    minimumLenghtToDisplayFilter: {
      type: Number,
      default: 7,
    },
    activatorNotDense: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      required: true,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isDialogActive: false,
      topFilter: null,
      validationErrors: this.errorMessages,
    };
  },
  watch: {
    errorMessages() {
      this.validationErrors = this.errorMessages;
    },
    isDialogActive() {
      if(!this.isDialogActive) {
        this.defocusWxInput();
      }
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
        this.defocusWxInput();
      },
    },
    filteredReasons() {
      if (!this.topFilter || this.topFilter === "") return this.reasons;
      return this.reasons.filter((i) => {
        return i.name?.toLowerCase()?.includes(this.topFilter.toLowerCase()) || i.header;
      });
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    isMobilePhone() {
      const vbpName = this.$vuetify.breakpoint.name;
      let phoneBreakpoint = false;
      if (vbpName === "xs") {
        phoneBreakpoint = true;
      }
      return phoneBreakpoint;
    },
  },
  methods: {
    headerHasItems(index, reasons) {
      if (reasons[index].name) return true;
      const nextItem = reasons[index + 1];
      return nextItem && !nextItem.header;
    },
    getComponentType(item) {
      if (item.header) return "v-list-item-title";
      if (item.divider) return "v-divider";
      return "v-list-item";
    },
    submit(itemId) {
      this.isDialogActive = false;
      this.$emit("input", itemId);
    },
    getReasonName(itemId) {
      let nonDeletedReason = this.reasons?.find((r) => r.id === itemId);
      if (nonDeletedReason) {
        return nonDeletedReason.name;
      } else {
        // The reason has not been found. It has been deleted (or its association to the PU has been deleted).
        let deletedReasonName = null;
        if (this.justificationRow && this.justificationRow.reasonId && this.downtime && this.downtime.justifications) {
          const justification = this.downtime.justifications.find((j) => {
            if (j.reason) {
              return j.reason.id === itemId;
            }
            return null;
          });
          const reason = justification ? justification.reason : null;
          deletedReasonName = reason
            ? `${reason.name} (${this.$t("justification.panel.deletedReason").toLowerCase()})`
            : this.$t("justification.panel.deletedReason");
        }
        return deletedReasonName;
      }
    },
    defocusWxInput() {
      this.$nextTick( () => {
        setTimeout(() => {
          //input is selected, unfocus the input
          this.$refs.wxInput.$refs.input.blur();
        });
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.v-text-field {
  &.v-text-field--dialog-activator {
    ::v-deep .v-input__control > .v-input__slot > .v-text-field__slot {
      // custom style to replace `v-icon mdi-menu-down`
      background: url("~@/assets/custom-icons/caret-down--dark.svg") no-repeat right center;
      background-size: 0.7rem;

      .theme--light & {
        background-image: url("~@/assets/custom-icons/caret-down--light.svg");
      }
    }
  }
}
// Designers requested the dialog to cover the entire height of the viewport
.v-dialog {
  ::v-deep &:not(.v-dialog--fullscreen) {
    max-height: 96%;
  }
  &__content {
    align-items: flex-start;
    justify-content: left;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.wx-modal-dialog {
  &.v-card {
    &.v-sheet {
      display: flex;
      flex-direction: column;
      background-color: var(--color-element-layer3);

      @media ($wx-isMobile) {
        height: 90vh;
      }
      @media ($wx-isMobilePhone) {
        height: 88vh;
      }
      @media ($wx-isNotMobile) {
        height: 94vh;
      }
    }
  }

  .v-card {
    // static header
    &__title {
      background-color: var(--color-element-layer3);

      .close-btn {
        position: absolute;
        z-index: 2;
        top: 0.25rem;
        right: 0.15rem;
      }
    }
    // scrollable body
    &__text {
      position: relative;
      z-index: 0;

      .v-list {
        background-color: transparent;
        box-shadow: none;

        .v-list-item__title,
        .v-list-item__content {
          font-size: var(--font-size-norm);
        }
        .v-list-item__title {
          padding-top: 0.75rem;
          padding-bottom: 0.25rem;
          color: var(--color-text-theme);
          font-weight: 700; /* Bold */
          border-top: 1px solid var(--color-border-theme);

          &:not(:first-of-type) {
            margin-top: 0.5rem;
          }
        }
        .v-list-item__content {
          padding: 0.5rem 0;
        }
      }
    }
  }
}
</style>
