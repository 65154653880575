<!--
  ToDo: Bind `closeMenu()` with `@click="tile.action"
-->
<template>
  <v-menu
    :wxid="$options.name"
    :close-on-content-click="false"
    v-model="menuState"
    content-class="bgcolor-element-layer3"
    min-width="16em"
    nudge-bottom="8"
    transition="slide-y-transition"
    rounded="lg"
    offset-y
    left
  >
    <template v-if="!giveawayViewToggle" v-slot:activator="{ on, attrs }">
      <wx-btn-icon
        :wxid="$options.name"
        :title="$t('tiles.selectorMenu.btnHint')"
        :x-small="isMobile"
        :small="!isMobile"
        v-bind="attrs"
        v-on="on"
        class="activator-btn"
        text
      >
        <v-icon>mdi-dots-vertical</v-icon>
      </wx-btn-icon>
    </template>

    <v-card :wxid="$options.name" class="tile-selector">
      <v-card-title tag="header" class="justify-space-between pb-3">
        <h3>
          {{ $t("tiles.selectorMenu.title") }}
        </h3>
        <a
          :href="$t('tiles.selectorMenu.academyLink.href')"
          :title="$t('tiles.selectorMenu.academyLink.hint')"
          class="d-inline-block wx-typo-sm font-weight-bold"
          target="kpiTiles"
        >
          {{ $t("tiles.selectorMenu.academyLink.text") }}
        </a>
      </v-card-title>
      <v-card-text tag="section" class="px-0 pb-1">
        <v-list tag="section" v-if="groupedItems" class="accordion-list transparent pt-0" dense flat tile>
          <fragment v-for="(group, mainLoop) in groupedItems" :key="mainLoop">
            <!-- 
              Toggleable Grouped Items
              -->
            <v-divider v-if="mainLoop === 5" />
            <v-list-group v-if="group.tiles" :value="group.selected" class="accordion-list__toggleable-group">
              <template v-slot:activator>
                <v-list-item-title class="accordion-list--row">
                  {{ group.name }}
                </v-list-item-title>
              </template>

              <v-list-item-group class="accordion-list__toggleable-group__list" tag="ul" no-action sub-group>
                <v-list-item
                  v-for="(tile, childLoop) in group.tiles"
                  :key="childLoop"
                  :disabled="tile.isLocked"
                  :class="{ 'primary--text': tile.selected }"
                  @click="tile.action"
                  class="accordion-list__toggleable-group__list__item"
                  tag="li"
                >
                  <v-list-item-title
                    :class="{ 'inline-status-icon': group.selected || group.isLocked }"
                    class="accordion-list--row"
                  >
                    {{ tile.name }}
                    <v-icon v-if="tile.selected && !tile.isLocked" small>mdi-check</v-icon>
                    <v-icon v-if="tile.isLocked" small>mdi-lock</v-icon>
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list-group>

            <!-- 
              Orphan Single Items
              -->
            <v-list-item
              v-else
              :disabled="group.isLocked"
              :class="{ 'primary--text': group.selected }"
              @click="group.action"
              class="accordion-list__single-list__item"
            >
              <v-list-item-title
                :class="{ 'inline-status-icon': group.selected || group.isLocked }"
                class="accordion-list--row"
              >
                {{ group.name }}
                <v-icon v-if="group.selected && !group.isLocked" small>mdi-check</v-icon>
                <v-icon v-if="group.isLocked" small>mdi-lock</v-icon>
              </v-list-item-title>
            </v-list-item>
          </fragment>
        </v-list>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import WxBtnIcon from "@/components/ui/WxBtnIcon";
import Tiles from "@/components/Tiles";

export default {
  name: "GroupedTileSelectorMenu",
  components: { WxBtnIcon },
  props: {
    tileIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      menuState: false,
      listModel: 0,
    };
  },
  computed: {
    ...mapGetters("dashboard", ["selectableTiles", "tileSelection", "giveawayViewToggle"]),
    ...mapGetters("packages", ["activePuHasRequiredFeature"]),
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    groupedItems() {
      let tileGroups = [
        {
          name: this.$t("tiles.selectorMenu.tilesGroupTitle.availability"),
          isLocked: false,
          selected: false,
          tiles: [
            {
              name: this.$t("tiles.availabilityPercent"),
              isLocked: !this.isAuthorized(Tiles.availability),
              selected: this.isSelected(Tiles.availability),
              action: () => this.selectTile(Tiles.availability),
            },
            {
              name: this.$t("tiles.totalUptime"),
              isLocked: !this.isAuthorized(Tiles.totalUptime),
              selected: this.isSelected(Tiles.totalUptime),
              action: () => this.selectTile(Tiles.totalUptime),
            },
            {
              name: this.$t("tiles.totalDowntimeSelector"),
              isLocked: !this.isAuthorized(Tiles.totalDowntime),
              selected: this.isSelected(Tiles.totalDowntime),
              action: () => this.selectTile(Tiles.totalDowntime),
            },
          ],
        },
        {
          name: this.$t("tiles.selectorMenu.tilesGroupTitle.performance"),
          isLocked: false,
          selected: false,
          tiles: [
            {
              name: this.$t("tiles.performancePercent"),
              isLocked: !this.isAuthorized(Tiles.performance),
              selected: this.isSelected(Tiles.performance),
              action: () => this.selectTile(Tiles.performance),
            },
            {
              name: this.$t("tiles.productThroughputPerHour"),
              isLocked: !this.isAuthorized(Tiles.currentProductThroughput),
              selected: this.isSelected(Tiles.currentProductThroughput),
              action: () => this.selectTile(Tiles.currentProductThroughput),
            },
            {
              name: this.$t("tiles.productThroughputPerMinute"),
              isLocked: !this.isAuthorized(Tiles.currentProductThroughputPerMinute),
              selected: this.isSelected(Tiles.currentProductThroughputPerMinute),
              action: () => this.selectTile(Tiles.currentProductThroughputPerMinute),
            },
            {
              name: this.$t("tiles.productSpeed5mPerHour"),
              isLocked: !this.isAuthorized(Tiles.currentProductSpeed5m),
              selected: this.isSelected(Tiles.currentProductSpeed5m),
              action: () => this.selectTile(Tiles.currentProductSpeed5m),
            },
            {
              name: this.$t("tiles.productSpeed5mPerMinute"),
              isLocked: !this.isAuthorized(Tiles.currentProductSpeed5mPerMinute),
              selected: this.isSelected(Tiles.currentProductSpeed5mPerMinute),
              action: () => this.selectTile(Tiles.currentProductSpeed5mPerMinute),
            },
          ],
        },
        {
          name: this.$t("tiles.selectorMenu.tilesGroupTitle.quality"),
          isLocked: false,
          selected: false,
          tiles: [
            {
              name: this.$t("tiles.qualityPercent"),
              isLocked: !this.isAuthorized(Tiles.quality),
              selected: this.isSelected(Tiles.quality),
              action: () => this.selectTile(Tiles.quality),
            },
            {
              name: this.$t("tiles.netQuantity.name"),
              isLocked: !this.isAuthorized(Tiles.rejectQuantity),
              selected: this.isSelected(Tiles.rejectQuantity),
              action: () => this.selectTile(Tiles.rejectQuantity),
            },
          ],
        },
        {
          name: this.$t("tiles.oee"),
          isLocked: !this.isAuthorized(Tiles.oee),
          selected: this.isSelected(Tiles.oee),
          action: () => this.selectTile(Tiles.oee),
        },
        {
          name: this.$t("tiles.ooe"),
          isLocked: !this.isAuthorized(Tiles.ooe),
          selected: this.isSelected(Tiles.ooe),
          action: () => this.selectTile(Tiles.ooe),
        },
        {
          name: this.$t("tiles.productionRunCompletion"),
          isLocked: !this.isAuthorized(Tiles.production),
          selected: this.isSelected(Tiles.production),
          action: () => this.selectTile(Tiles.production),
        },
        {
          name: this.$t("tiles.selectorMenu.tilesGroupTitle.quantity"),
          isLocked: false,
          selected: false,
          tiles: [
           {
              name: this.$t("tiles.currentProductQuantity"),
              isLocked: !this.isAuthorized(Tiles.currentProductQuantity),
              selected: this.isSelected(Tiles.currentProductQuantity),
              action: () => this.selectTile(Tiles.currentProductQuantity),
            },
            {
              name: this.$t("tiles.totalLineQuantity"),
              isLocked: !this.isAuthorized(Tiles.allProductsQuantity),
              selected: this.isSelected(Tiles.allProductsQuantity),
              action: () => this.selectTile(Tiles.allProductsQuantity),
            },
          ],
        },
        {
          name: this.$t("tiles.selectorMenu.tilesGroupTitle.giveaway"),
          isLocked: false,
          selected: false,
          tiles: [
            {
              name: this.$t("tiles.productGiveawayPercent"),
              isLocked: !this.isAuthorized(Tiles.productGiveawayPercent),
              selected: this.isSelected(Tiles.productGiveawayPercent),
              action: () => this.selectTile(Tiles.productGiveawayPercent),
            },
            {
              name: this.$t("tiles.productGiveaway"),
              isLocked: !this.isAuthorized(Tiles.productGiveaway),
              selected: this.isSelected(Tiles.productGiveaway),
              action: () => this.selectTile(Tiles.productGiveaway),
            },
            {
              name: this.$t("tiles.productAverageGiveaway"),
              isLocked: !this.isAuthorized(Tiles.productAverageGiveaway),
              selected: this.isSelected(Tiles.productAverageGiveaway),
              action: () => this.selectTile(Tiles.productAverageGiveaway),
            },
          ],
        },
        {
          name: this.$t("tiles.selectorMenu.tilesGroupTitle.cycles"),
          isLocked: false,
          selected: false,
          tiles: [
            {
              name: this.$t("tiles.currentAverageCycleTimeSelector"),
              isLocked: !this.isAuthorized(Tiles.cycleTime),
              selected: this.isSelected(Tiles.cycleTime),
              action: () => this.selectTile(Tiles.cycleTime),
            },
            {
              name: this.$t("tiles.cycleQuantitySelector"),
              isLocked: !this.isAuthorized(Tiles.cycleQuantity),
              selected: this.isSelected(Tiles.cycleQuantity),
              action: () => this.selectTile(Tiles.cycleQuantity),
            },
          ],
        },
      ];
      tileGroups.forEach((group) => {
        if (group.tiles) {
          group.selected = group.tiles.some((tile) => tile.selected);
        }
      });
      return tileGroups;
    },
  },
  methods: {
    ...mapActions("dashboard", ["setTileByIndex"]),
    selectTile(selectedTile) {
      this.setTileByIndex({ tile: selectedTile.name, index: this.tileIndex });
    },
    isSelected(tile) {
      const currentTile = this.tileSelection[this.tileIndex];
      return tile.name === currentTile;
    },
    isAuthorized(tile) {
      return this.activePuHasRequiredFeature(tile.requiredFeature);
    },
    closeMenu() {
      this.menuState = false;
    },
  },
};
</script>

<style lang="scss" scoped>
// Extended style
.extend_activeRootItem {
  color: var(--color-text-theme);
  background-color: var(--color-control-contrast-theme);
}

// Styles
.activator-btn {
  &.v-btn {
    position: absolute;
    z-index: 1;
    top: var(--box-padding-dashboard);
    right: var(--box-padding-dashboard);
    min-width: 0 !important;
    padding: 0 !important;
  }
}

.tile-selector {
  &.v-card {
    background-color: var(--color-element-layer3);
    border-radius: 0;
  }
}

.accordion-list {
  &.v-list {
    .v-icon {
      color: inherit;
    }
    // BEM
    .accordion-list {
      &--row {
        // mdi-lock and mdi-check icons
        &.inline-status-icon {
          display: flex;
          justify-content: space-between;

          .v-icon {
            margin-right: 3px; // vertically aligned with chevrons
          }
        }
      }
      /**
       * Orphan Single Items
       */
      &__single-list {
        &__item {
          &.v-list-item {
            &.v-item--active {
              @extend .extend_activeRootItem;
            }
          }
        }
      }
      /**
       * Toggleable Grouped Items
       */
      &__toggleable-group {
        ::v-deep .v-list-group__header {
          &.v-list-item--active {
            @extend .extend_activeRootItem;
          }
        }
        &__list {
          box-shadow: var(--box-shadow-small-elevation);

          &__item {
            // padding-left: 2em; // indentating is NOT on mockup

            &.v-list-item {
              &:not(.v-item--active):not(.v-list-item--disabled) {
                color: var(--color-text-subtle-theme);
              }
              &.v-item--active {
                color: var(--color-text-theme); // over-rule Vuetify primary
              }
            }
          }
        }
      }
    }
  }
}
</style>
