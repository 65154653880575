<template>
  <fragment>
    <header :wxid="$options.name">
      <h1 class="main-title mb-sm-4" :class="isGiveawayActive ? 'main-title--toggle-buttons-active' : false">
        <span class="main-title__text">
          {{ productionUnitNameAsTitle }}
        </span>
        <v-btn-toggle v-if="isGiveawayActive" class="main-title__buttons ml-sm-3 ml-md-6" borderless dense>
          <wx-btn-standard
            @click="toProduction()"
            :title="$t('dashboard.timelineToggleBtn_hint')"
            :color="getTimelineButtonColor()"
            :class="{ disabled: !giveawayViewToggle }"
            class="production-toggle"
          >
            {{ $t("dashboard.timelineToggleBtn") }}
          </wx-btn-standard>
          <wx-btn-standard
            @click="toGiveaway()"
            :title="$t('dashboard.giveawayToggleBtn_hint')"
            :color="getGiveawayButtonColor()"
            :class="{ disabled: giveawayViewToggle }"
            class="giveaway-toggle"
          >
            {{ $t("dashboard.giveawayToggleBtn") }}
          </wx-btn-standard>
        </v-btn-toggle>
      </h1>
      <div>
        <p class="d-flex align-center mb-4">
          <span>
            <strong>{{ productSkuAndNameText }}</strong>
          </span>
          <span class="work-order-lot wx-typo-xm ml-6">
            {{ workOrderAndLotInformationText }}
          </span>
          <span v-if="isLiveData" :title="$t('dashboard.liveDataTitle')" class="primary--text ml-3 font-weight-bold">
            {{ $t("common.live") }}
          </span>
          <span class="ml-3 ml-lg-5" v-if="!isLiveData && timeFrame === 'production_run'">
            <span class="d-inline-block">
              <b>{{ $t("common.from") + " " }}</b>
              {{ formatProductionRunDate(selectedProductionRun.start_date) }}
            </span>
            <span class="d-inline-block ml-3">
              <b>{{ $t("common.to") + " " }}</b>
              {{ formatProductionRunDate(selectedProductionRun.end_date) }}
            </span>
          </span>
        </p>
      </div>
      <wx-time-frame-date-picker
        ref="timeFrameDatePicker"
        v-if="this.activeFactory && this.activeProductionUnitId"
        :max-past-months="maxPastMonths"
        :work-shift-coverage="workShiftCoverage"
        :production-run-coverage="productionRunCoverage"
        :is-display-optional-column="giveawayViewToggle"
        :row-vertical-spacing-class="giveawayViewToggle ? 'main-title-tab--giveaway' : 'main-title-tab--production'"
        optional-col-class="flex-column flex-sm-row align-center justify-md-end flex-button-column-gap"
      >
        <!-- Must be on the right toggled view and must have a sampling configuration to see this button -->
        <template
          v-if="giveawayViewToggle && isGiveawaySamplingModeActive && !isPresenter"
          v-slot:optional-column-container
        >
          <measures-export-dialog measure-type="giveawaysamples" activator-button-css-class="mb-2 mb-sm-0" />
          <wx-btn-standard
            @click="startSampling"
            :disabled="!currentProduct"
            :title="$t('dashboard.productSampling.startSampling')"
            color="primary"
            class="sampling-btn"
            large
          >
            {{ $t("dashboard.productSampling.samplingBtn") }}
          </wx-btn-standard>
        </template>
        <template v-slot:tag-selector>
          <dashboard-tag-selector />
        </template>
      </wx-time-frame-date-picker>
    </header>
    <article :wxid="$options.name" class="main-content-wrapper">
      <!-- - - - - - - - - -
      Production (default)
      -->
      <section v-if="!giveawayViewToggle" class="dashboard-content dashboard-content--production">
        <v-tabs-items :value="0">
          <v-tab-item>
            <div class="tab-inner">
              <div class="tile-container">
                <section ref="tilesRow" class="tile-slider" :class="{ isDataSourceAlerts: isDataSourceAlerts }">
                  <tile-disconnected v-if="!isMobile" />
                  <component
                    v-for="(tileName, index) in tileSelection"
                    :key="`tileSelection-${index}`"
                    :is="!isTilesLoaded ? 'tile-loading' : 'tile-' + tileName"
                    :tile-index="index"
                    type="image"
                  />
                  <tile-disconnected v-if="isMobile && isDataSourceAlerts" />
                </section>
              </div>

              <div :class="{ isUp: selectedDowntime }" class="panel-container panel-container--production-timeline">
                <panel-production-timeline v-show="isPanelLoaded" :show-timeline="!isDurationOverThreeDays" />
                <wx-skeleton-loader v-show="!isPanelLoaded" type="image" />
              </div>
              <div
                v-if="isPanelLoaded"
                :class="{ 'production-timeline-isUp': selectedDowntime }"
                class="panel-container panel-container--justification"
              >
                <justification-panel v-show="!isPresenter && selectedDowntime" :panel-height="tilesRowHeight" />
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </section>
      <!-- - - - - - - - - -
      Giveaways
      -->
      <section v-if="giveawayViewToggle" class="dashboard-content dashboard-content--giveaway">
        <v-tabs-items :value="0">
          <v-tab-item>
            <div class="tab-inner">
              <div class="tile-container">
                <section class="tile-slider" :class="{ isDataSourceAlerts: isDataSourceAlerts }">
                  <tile-disconnected v-if="!isMobile" />
                  <component
                    v-for="(tile, index) in giveawayTiles"
                    :key="`giveawayTile-${index}`"
                    :is="!isTilesLoaded ? 'tile-loading' : 'tile-' + tile.name"
                    :tile-index="index"
                  />
                  <tile-disconnected v-if="isMobile && isDataSourceAlerts" />
                </section>
              </div>

              <div class="panel-container panel-container--giveaway">
                <panel-giveaway
                  v-if="isPanelLoaded"
                  :show-giveaway="!isDurationOverThreeDays"
                />
                <wx-skeleton-loader v-else type="image" />
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </section>
    </article>
    <existing-session-dialog
      :show="showExistingSessionDialog"
      @back="onExistingSessionBack"
      @resume="onExistingSessionResume"
    />
    <wrong-product-selected-dialog
      v-if="samplingSessionInProgress"
      :show="showWrongProductForSessionDialog"
      :session="samplingSessionInProgress"
      @back="onExistingSessionBack"
      @cancel="onWrongProductSelectedCancel"
    />
    <confirm-cancel-dialog :show="cancellingSession" @back="onExistingSessionBack" @cancel="cancelSessionInProgress" />
  </fragment>
</template>

<script>
import InlineSvg from "vue-inline-svg";
import PanelProductionTimeline from "@/components/dashboard/PanelProductionTimeline";
import PanelGiveaway from "@/components/dashboard/PanelGiveaway";
import JustificationPanel from "@/components/dashboard/JustificationPanel";
import TileAvailability from "@/components/dashboard/tiles/TileAvailability";
import TileTotalUptime from "@/components/dashboard/tiles/TileTotalUptime";
import TileTotalDowntime from "@/components/dashboard/tiles/TileTotalDowntime";
import TileDisconnected from "@/components/dashboard/tiles/TileDisconnected";
import TilePerformance from "@/components/dashboard/tiles/TilePerformance";
import TileOee from "@/components/dashboard/tiles/TileOee";
import TileAllProductsQuantity from "@/components/dashboard/tiles/TileAllProductsQuantity";
import TileCurrentProductQuantity from "@/components/dashboard/tiles/TileCurrentProductQuantity";
import TileProductThroughput from "@/components/dashboard/tiles/TileProductThroughput";
import TileProductThroughputPerMinute from "@/components/dashboard/tiles/TileProductThroughputPerMinute";
import TileProductSpeed5m from "@/components/dashboard/tiles/TileProductSpeed5m";
import TileProductGiveaway from "@/components/dashboard/tiles/TileProductGiveaway";
import TileProductGiveawayPercent from "@/components/dashboard/tiles/TileProductGiveawayPercent";
import TileInProgressDowntime from "@/components/dashboard/tiles/TileInProgressDowntime";
import TileProductAverageGiveaway from "@/components/dashboard/tiles/TileProductAverageGiveaway";
import TileWorkOrder from "@/components/dashboard/tiles/TileWorkOrder";
import TileLot from "@/components/dashboard/tiles/TileLot";
import WxSelect from "@/components/ui/WxSelect";
import { mapGetters, mapActions } from "vuex";
import { DateTime } from "luxon";
import moment from "moment-timezone";
import PackageFeatures from "@/components/PackageFeatures";
import Tiles from "@/components/Tiles";
import WxBtnStandard from "@/components/ui/WxBtnStandard";
import RouteService from "@/router/RouteService";
import GiveawaySamplingService from "@/components/productsamplingsession/GiveawaySamplingService";
import ExistingSessionDialog from "@/components/productsamplingsession/dialogs/ExistingSessionDialog.vue";
import WrongProductSelectedDialog from "@/components/productsamplingsession/dialogs/WrongProductSelectedDialog.vue";
import ConfirmCancelDialog from "@/components/productsamplingsession/dialogs/ConfirmCancelDialog.vue";
import TileProduction from "@/components/dashboard/tiles/TileProduction.vue";
import TileQuality from "@/components/dashboard/tiles/TileQuality.vue";
import TileRejectQuantity from "@/components/dashboard/tiles/TileRejectQuantity.vue";
import TileProductSpeed5mPerMinute from "@/components/dashboard/tiles/TileProductSpeed5mPerMinute.vue";
import TileCycleTime from "@/components/dashboard/tiles/TileCycleTime.vue";
import TileCycleQuantity from "@/components/dashboard/tiles/TileCycleQuantity.vue";
import goTo from "vuetify/lib/services/goto";
import DemoService from "@/components/DemoService";
import MeasuresExportDialog from "@/components/productionunit/MeasuresExportDialog";
import WxTimeFrameDatePicker from "@/components/ui/timeframedatepicker/WxTimeFrameDatePicker.vue";
import { isDurationGreaterThanNDays, PRODUCTION_RUN } from "@/store/TimeUtils";
import TileLoading from "@/components/dashboard/tiles/TileLoading.vue";
import WxSkeletonLoader from "@/components/ui/WxSkeletonLoader.vue";
import TileOoe from "@/components/dashboard/tiles/TileOoe.vue";
import DashboardTagSelector from "@/components/ui/tagselector/DashboardTagSelector.vue";

const INTERVAL_1_SECOND = 1000;
const INTERVAL_10_SECONDS = 10000;
const INTERVAL_1_MINUTE = 60000;

export default {
  name: "DashboardView",
  components: {
    DashboardTagSelector,
    WxSkeletonLoader,
    TileLoading,
    WxTimeFrameDatePicker,
    ConfirmCancelDialog,
    WrongProductSelectedDialog,
    ExistingSessionDialog,
    InlineSvg,
    PanelProductionTimeline,
    JustificationPanel,
    PanelGiveaway,
    TileAvailability,
    TileTotalUptime,
    TileTotalDowntime,
    TileAllProductsQuantity,
    TileCurrentProductQuantity,
    TileProductThroughput,
    TileProductThroughputPerMinute,
    TileProductSpeed5m,
    TileProductSpeed5mPerMinute,
    TilePerformance,
    TileOee,
    TileProductGiveaway,
    TileProductGiveawayPercent,
    TileInProgressDowntime,
    TileDisconnected,
    TileQuality,
    TileRejectQuantity,
    WxSelect,
    TileProductAverageGiveaway,
    TileWorkOrder,
    TileLot,
    TileProduction,
    TileCycleTime,
    TileCycleQuantity,
    WxBtnStandard,
    MeasuresExportDialog,
    TileOoe,
  },
  data() {
    return {
      fetchDashboardInterval: null,
      fetchGiveawayDashboardInterval: null,
      setCurrentDowntimeDurationInterval: null,
      fetchDataSourceAlertsInterval: null,
      isDataSourceAlerts: false,
      showExistingSessionDialog: false,
      showWrongProductForSessionDialog: false,
      cancellingSession: false,
      tilesRowHeight: "0px",
      fetchProductionUnitCoverageInterval: null,
      fetchProductionUnitTagsInterval: null,
    };
  },
  computed: {
    ...mapGetters("user", [
      "isPresenter",
      "language",
      "isWorximityAdmin",
      "worximityAdminAllowedCoverageInMonths",
      "email",
      "factories",
    ]),
    ...mapGetters("dashboard", [
      "giveawayViewToggle",
      "activeProductionUnitId",
      "selectedDowntime",
      "productionUnitName",
      "tileSelection",
      "activeProductionUnit",
      "currentProduct",
      "currentWorkOrder",
      "currentLot",
      "samplingSessionInProgress",
      "oldestDataSourceAlert",
      "workShiftCoverage",
      "productionRunCoverage",
      "productionUnitProducts",
      "isTilesLoaded",
      "isPanelLoaded",
    ]),
    ...mapGetters("navigation", [
      "activeFactory",
      "isLiveData",
      "timeFrame",
      "isTimeRangeReady",
      "durationMillis",
      "selectedProductionRun",
    ]),
    ...mapGetters("packages", ["activePuHasRequiredFeature"]),
    maxPastMonths() {
      if (this.isWorximityAdmin) {
        return this.worximityAdminAllowedCoverageInMonths;
      }
      return 1;
    },
    giveawayTiles() {
      return Tiles.getGiveawayTiles();
    },
    language() {
      return this.$i18n.locale;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    isGiveawayActive() {
      if (!this.activeProductionUnit) return false;
      return this.activePuHasRequiredFeature(PackageFeatures.giveaway);
    },
    isGiveawaySamplingModeActive() {
      if (!this.activeProductionUnit) return false;
      return (
        this.activePuHasRequiredFeature(PackageFeatures.giveaway) &&
        this.activeProductionUnit.giveaway_sampling_configuration !== null
      );
    },
    productionUnitNameAsTitle() {
      if (this.productionUnitName) {
        if (this.productionUnitName.length > 30) {
          return this.productionUnitName.slice(0, 30) + "\u2026";
        } else {
          return this.productionUnitName;
        }
      }
      return "";
    },
    timezone() {
      if (this.activeFactory && this.activeFactory.timezone) {
        return this.activeFactory.timezone;
      } else {
        const zone = moment.tz.guess(true);
        return zone === null || zone === undefined ? "America/Montreal" : zone;
      }
    },
    isDurationOverThreeDays() {
      return isDurationGreaterThanNDays(this.durationMillis, 3);
    },
    isDurationOverFourteenDays() {
      return isDurationGreaterThanNDays(this.durationMillis, 14);
    },
    productSkuAndNameText() {
      if (this.timeFrame === PRODUCTION_RUN) {
        if (!this.selectedProductionRun?.sku) {
          return this.$t("dashboard.productionRun.noProductSelected");
        }
        const sku = this.selectedProductionRun.sku;
        const name = this.selectedProductionRun.product_name;
        if (sku && name) {
          return `${sku} (${name})`;
        } else {
          return sku;
        }
      } else {
        if (!this.currentProduct) {
          return this.$t("dashboard.productionRun.noProductSelected");
        }
        const sku = this.currentProduct.sku;
        const name = DemoService.maskProductNameIfNecessary(this.email, this.currentProduct.name);
        if (sku && name) {
          return `${sku} (${name})`;
        }
        return sku;
      }
    },
    workOrderAndLotInformationText() {
      if (this.timeFrame === PRODUCTION_RUN) {
        const workOrderLabel = this.selectedProductionRun.work_order_label;
        const lotLabel = this.selectedProductionRun.lot_label;
        if (!workOrderLabel && !lotLabel) return "";
        let separator = workOrderLabel && lotLabel ? " / " : "";
        let workOrderText = workOrderLabel
          ? this.$t("dashboard.panelHeader.workOrder", [workOrderLabel])
          : "";
        let lotNumberText = lotLabel ? this.$t("dashboard.panelHeader.lotNumber", [lotLabel]) : "";
        return workOrderText + separator + lotNumberText;
      } else {
        if (!this.currentWorkOrder && !this.currentLot) {
          return "";
        }
        let separator = this.currentWorkOrder && this.currentLot ? " / " : "";
        let workOrderText = this.currentWorkOrder
          ? this.$t("dashboard.panelHeader.workOrder", [this.currentWorkOrder.number])
          : "";
        let lotNumberText = this.currentLot ? this.$t("dashboard.panelHeader.lotNumber", [this.currentLot.number]) : "";
        return workOrderText + separator + lotNumberText;
      }
    },
  },
  watch: {
    oldestDataSourceAlert() {
      this.isDataSourceAlerts = this.oldestDataSourceAlert !== null && this.oldestDataSourceAlert !== undefined;
    },
    activeProductionUnitId() {
      let idFromRoute = this.$route.params.id;
      if (idFromRoute && idFromRoute !== this.activeProductionUnitId) {
        const isIdFromRoutePartOfActiveFactory = this.activeFactory.productionUnits.includes(
          (pu) => pu.id === idFromRoute,
        );
        if (!isIdFromRoutePartOfActiveFactory) {
          // The PU from the route is not part of thr active factory, we need to switch factory first

          const factory = this.factories.find((factory) => {
            const pu = factory.productionUnits.find((pu) => pu.id === idFromRoute);
            if (pu) {
              // The Production Unit is part of this factory
              return factory;
            } else {
              return null;
            }
          });
          if (factory) {
            this.setActiveFactory(factory);
          }
        }
        this.setActiveProductionUnitId(idFromRoute);
        this.fetchProductionUnitProducts();
      }
      this.restartFetch();
    },
    giveawayViewToggle() {
      if (!this.giveawayViewToggle) this.setGiveawayForSku(null);
      this.clearIntervals();
      this.startFetchDashboard();
    },
    selectedDowntime(current, previous) {
      // slowly scroll down to JustificationPanel when clicking a downtime
      if (!current || !this.$vuetify.breakpoint.mobile || (current && previous && current.id === previous.id)) return;
      goTo(document.body.scrollHeight, {
        duration: 1500,
      });
    },
    isTimeRangeReady() {
      this.restartFetch();
    },
    activeFactory() {
      if (this.activeFactory?.id) this.fetchProductionUnitProducts();
    },
  },
  methods: {
    ...mapActions("navigation", ["fetchFactoryDataSourceAlerts", "setActiveFactory"]),
    ...mapActions("dashboard", [
      "setActiveProductionUnitId",
      "fetchDashboard",
      "setCurrentDowntimeDuration",
      "setSamplingSessionInProgress",
      "setGiveawayViewToggle",
      "fetchProductionUnitCoverage",
      "fetchProductionUnitProducts",
      "setGiveawayForSku",
      "fetchProductionUnitTags",
    ]),
    ...mapActions("operation", ["handleHttpError"]),
    startSampling() {
      if (!this.currentProduct) return null;
      // 1. Fetch the current sampling session in progress if any
      GiveawaySamplingService.fetchSessionInProgress(this.activeProductionUnitId)
        .then((httpResponse) => {
          // if 200, set the session in the store
          this.setSamplingSessionInProgress(httpResponse.data);
          // check if the session in progress matches the currently selected product
          if (this.currentProduct.sku === this.samplingSessionInProgress.sku) this.showExistingSessionDialog = true;
          else this.showWrongProductForSessionDialog = true;
        })
        .catch((httpError) => {
          // if 404, create a new sampling session
          if (
            httpError.response.status === 404 &&
            httpError.response.data.errors[0] &&
            httpError.response.data.errors[0].code === "CS_GS_GET_10002"
          ) {
            // create new sampling session
            this.createNewSamplingSession();
          } else this.handleHttpError(httpError);
        });
    },
    createNewSamplingSession() {
      GiveawaySamplingService.createSamplingSession(this.activeProductionUnitId, { sku: this.currentProduct.sku })
        .then((httpResponse) => {
          this.setSamplingSessionInProgress(httpResponse.data.resource);
          this.$router.push(RouteService.toProductSampling(this.currentProduct.id));
        })
        .catch(this.handleHttpError);
    },
    onExistingSessionBack() {
      this.setSamplingSessionInProgress(null);
      this.showExistingSessionDialog = false;
      this.showWrongProductForSessionDialog = false;
      this.cancellingSession = false;
    },
    onWrongProductSelectedCancel() {
      this.showWrongProductForSessionDialog = false;
      this.cancellingSession = true;
    },
    onExistingSessionResume() {
      this.$router.push(RouteService.toProductSampling(this.currentProduct.id));
    },
    cancelSessionInProgress() {
      GiveawaySamplingService.cancelSamplingSession(
        this.activeProductionUnitId,
        this.samplingSessionInProgress.session_id,
      )
        .then(() => this.createNewSamplingSession())
        .catch(this.handleHttpError);
    },
    getTimelineButtonColor() {
      return this.giveawayViewToggle ? null : "primary";
    },
    getGiveawayButtonColor() {
      return this.giveawayViewToggle ? "primary" : null;
    },
    toProduction() {
      this.setGiveawayViewToggle(false);
    },
    toGiveaway() {
      this.setGiveawayViewToggle(true);
    },
    calculateMinDate() {
      return DateTime.now()
        .setZone(this.timezone)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .minus({ months: this.maxPastMonths })
        .toISO(); // Allow to navigate up to `this.maxPastMonths` month behind !
    },
    /**
     * INTERVALS
     */
    startFetchDashboard() {
      clearInterval(this.fetchDashboardInterval);
      this.fetchDashboard();
      this.fetchDashboardInterval = setInterval(() => this.fetchDashboard(), INTERVAL_10_SECONDS);
    },
    startCurrentDowntimeDuration() {
      clearInterval(this.setCurrentDowntimeDurationInterval);
      this.setCurrentDowntimeDuration();
      this.setCurrentDowntimeDurationInterval = setInterval(() => this.setCurrentDowntimeDuration(), INTERVAL_1_SECOND);
    },
    startFetchFactoryDataSourceAlerts() {
      clearInterval(this.fetchDataSourceAlertsInterval);
      this.fetchFactoryDataSourceAlerts();
      this.fetchDataSourceAlertsInterval = setInterval(() => this.fetchFactoryDataSourceAlerts(), INTERVAL_1_MINUTE);
    },
    startFetchProductionUnitCoverage() {
      clearInterval(this.fetchProductionUnitCoverageInterval);
      this.fetchProductionUnitCoverage({
        startTime: this.calculateMinDate(),
        endTime: DateTime.now()
          .setZone(this.timezone)
          .toISO(),
      });
      this.fetchProductionUnitCoverageInterval = setInterval(
        () =>
          this.fetchProductionUnitCoverage({
            startTime: this.calculateMinDate(),
            endTime: DateTime.now()
              .setZone(this.timezone)
              .toISO(),
          }),
        INTERVAL_10_SECONDS,
      );
    },
    startFetchProductionUnitTags() {
      clearInterval(this.fetchProductionUnitTagsInterval);
      this.fetchProductionUnitTags();
      this.fetchProductionUnitTagsInterval = setInterval(this.fetchProductionUnitTags, INTERVAL_10_SECONDS);
    },
    restartFetch() {
      // each interval is cleared before being set
      this.startFetchProductionUnitCoverage();
      this.startFetchFactoryDataSourceAlerts();
      this.startCurrentDowntimeDuration();
      this.startFetchDashboard();
      this.startFetchProductionUnitTags();
    },
    clearIntervals() {
      clearInterval(this.fetchDashboardInterval);
      clearInterval(this.fetchDataSourceAlertsInterval);
      clearInterval(this.setCurrentDowntimeDurationInterval);
      clearInterval(this.fetchProductionUnitCoverageInterval);
      clearInterval(this.fetchProductionUnitTagsInterval);
    },
    formatProductionRunDate(date) {
      let startDate = this.selectedProductionRun.start_date;
      let endDate = this.selectedProductionRun.end_date;
      if (startDate.slice(0, 10) === endDate.slice(0, 10)) {
        return date.slice(11, 19);
      } else {
        return date.slice(0, 19).replace("T", " ");
      }
    },
  },
  mounted() {
    this.setGiveawayViewToggle(false);
    this.tilesRowHeight = this.$refs.tilesRow ? this.$refs.tilesRow[0]?.clientHeight + "px" : "0px";
    this.fetchProductionUnitProducts();
  },
  beforeDestroy() {
    this.clearIntervals();
  },
};
</script>

<style lang="scss" scoped>
.dropdown-nav {
  flex-grow: 0;
  max-width: 20rem;

  @media ($wx-xl-min) {
    max-width: 22rem;
  }
}

/**
 * Manage the height distribution of the 3 main zone of the page
 */

.main-content-wrapper,
.dashboard-content {
  display: flex;
  flex-direction: column;
}

// Manage responsive height
.main-content-wrapper {
  min-height: 500px;

  @media ($wx-isNotMobile) {
    height: 100%;
  }

  .dashboard-content {
    height: 100%;

    .v-item-group {
      flex-grow: 1;
    }
  }
}

.v-application {
  &:not(.presenter) {
    .main-content-wrapper {
      @media ($wx-lg-min) {
        min-height: 600px;
      }
      @media ($wx-xl-min) {
        min-height: 700px;
      }
    }
  }
  /**
   * If presenter TV display mode, prevent vertical scrollbar.
   * Officially we only support viewport ration of 16:9,
   * but up to a squessed ratio of 199:55, everything displays OK.
   */
  &.presenter {
    .main-content-wrapper {
      @media ($wx-lg-min) {
        min-height: 540px;
      }
      @media ($wx-xl-min) {
        min-height: 650px;
      }
    }
  }
}

.v-window {
  display: flex;
  overflow: visible;

  ::v-deep .v-window__container {
    flex-grow: 1;
    margin: 0 calc(#{var(--grid-gutter)} * -1);
  }
  .v-window-item {
    position: relative;
    padding: calc((var(--grid-gutter) / 3) + 12px) var(--grid-gutter) var(--grid-gutter);

    .tab-inner {
      position: relative;
      height: 100%;
    }

    @media ($wx-md-min) {
      height: 100%;
      overflow: hidden;

      .tab-inner {
        height: 100%;
        min-height: 450px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
}

/**
 * Dashbords's 3 rows of contents in each tabs
 * within `section.dashboard-content`
 */
.tile-container,
.panel-container {
  position: relative;
}

// 1st row
.tile-container {
  margin-bottom: var(--grid-gutter);

  @media ($wx-md-min) {
    height: calc(40% - var(--grid-gutter));
    justify-content: flex-start;
  }

  .tile-slider {
    display: flex;
    column-gap: var(--grid-gutter);
    flex-flow: row wrap;
    height: 100%;

    @media (max-width: $wx-max-width-phone-vertical) {
      justify-content: center;
    }
    @media (min-width: $wx-min-width-phone-vertical) {
      justify-content: flex-start;
    }
    @media ($wx-md-min) {
      column-gap: 0;
      flex-flow: row nowrap;
      transform: translateX(calc((100% - var(--grid-gutter) / 2 * 4) / 5 * -1 - (var(--grid-gutter) / 2)));
      transition: var(--smooth-transition);

      &::after {
        content: none;
      }

      // .tile-slider > .tile
      ::v-deep .tile.v-card {
        &:first-child,
        &:nth-child(6) {
          transition: var(--smooth-transition);
        }
        &:first-child {
          transform: translateX(calc(var(--grid-gutter) * -1.5));
        }
      }
      // .tile-slider.isDataSourceAlerts
      &.isDataSourceAlerts {
        transform: translateX(0);

        // .tile-slider.isDataSourceAlerts > .tile
        .tile {
          &:first-child {
            transform: translateX(0);
          }
          &:nth-child(6) {
            transform: translateX(calc(var(--grid-gutter) * 1.5));
          }
        }
      }
    }
    @media ($wx-lg-min) {
      // .tile-slider
      margin-bottom: calc(var(--grid-gutter) * 1.5);
    }
  }
}

.panel-container {
  // 2nd row panels
  &--production-timeline,
  &--giveaway {
    height: auto;
    margin-bottom: var(--grid-gutter);

    @media ($wx-md-min) {
      margin-bottom: 0;
      position: absolute;
      z-index: 1;
      height: 60%;
      width: 100%;
      left: 0;
      bottom: 0;
      transition: var(--big-transition); // big elements requires more time to move to prevent dizziness

      &.isUp {
        transform: translateY(-66.666666%);
      }
    }

    // Both panel-timeline-oee and panel-giveaway
    ::v-deep .wx-panel.v-card {
      transition: var(--smooth-transition);
      height: 100%;

      @at-root .panel-container--production-timeline.isUp #{&} {
        box-shadow: var(--box-shadow-high-elevation);
      }
    }
  }
  // 3rd row panel
  &--justification {
    ::v-deep .panel-downtime-justification {
      height: auto;
    }

    @media ($wx-md-min) {
      height: calc(40% - var(--grid-gutter));

      ::v-deep .panel-downtime-justification {
        height: calc(100% - 1px);
      }
    }
  }
}

// Conditional chip
.main-title {
  .no-data-warning {
    max-height: 1.3em;
    color: black;
    font-weight: 400; // Normal
  }
  ::v-deep .v-input--switch {
    .v-input__slot {
      margin-bottom: 0;
    }
    .v-label {
      font-size: var(--font-size-h3) !important;
    }
  }

  &--toggle-buttons-active {
    // mobilePhone
    @media ($wx-xs-max) {
      .main-title {
        &__text {
          display: block;
          margin-bottom: 0.25rem;
        }
        &__buttons.v-item-group {
          width: 100%;
          .v-btn {
            width: 50%;
          }
        }
      }
    }
    // mobileTablet & desktop
    @media ($wx-sm-min) {
      display: flex;
      align-items: center;

      .main-title {
        &__buttons {
          margin-top: 0.05em;
        }
      }
    }
  }
}

// WxDatePickerByTimeframe
::v-deep .main-title-tab--giveaway .datepicker-col--first-column {
  // Fixing responsive issue in FR on mini tablets
  @media (min-width: $wx-breakpoint-md) and (max-width: $wx-breakpoint-lg) {
    flex-direction: column;
    .timeframe--aside {
      padding-left: 0.6rem;
      // .timeframe--aside__shift-name
      &__shift-name,
      .wx-contextualized-help__activator.text {
        position: absolute;
      }
      // .timeframe--aside__datepicker-activator
      &__datepicker-activator {
        margin-left: 14.25rem !important;
      }
    }
  }
}
::v-deep .datepicker-col--optional-column-container {
  .measures-export-dialog__activator-btn,
  .sampling-btn {
    @media ($wx-isMobile) {
      flex-grow: 1;

      @media ($wx-xs-max) {
        width: 70%;
        min-width: 15rem; // matching FR button text lenght
      }
    }
    @media ($wx-isNotMobile) {
      flex-grow: 0;
    }
    @media ($wx-lg-min) {
      min-width: 8.5rem;
    }
    @media ($wx-xl-min) {
      min-width: 14rem;
    }
  }
}
</style>
