<template>
  <v-card
    :wxid="$options.name"
    :class="{ disabled: isTileDisabled }"
    class="tile all-products-quantity unit-name"
    tag="section"
    sf="1"
  >
    <grouped-tile-selector-menu :tile-index="tileIndex" />
    <div class="tile-inner">
      <h3 :title="$t('tiles.allProductsQuantity_unit', { unit: unitName })" class="primary-title">
        {{ $t("tiles.allProductsQuantity_unit", { unit: unitName }) }}
        <v-icon v-if="!isTileAuthorized()">mdi-lock</v-icon>
      </h3>
      <h2 class="primary-value">{{ primaryValue }}</h2>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ProductionUnitService from "@/components/productionunit/ProductionUnitService";
import GroupedTileSelectorMenu from "@/components/dashboard/tiles/GroupedTileSelectorMenu";
import TileBase from "@/components/dashboard/tiles/TileBase";
import Tiles from "@/components/Tiles";
import Helpers from "@/helpers";

export default {
  name: "TileAllProductsQuantity",
  extends: TileBase,
  inheritAttrs: false,
  components: { GroupedTileSelectorMenu },
  computed: {
    ...mapGetters("dashboard", ["productionUnitConvertedUnit", "allProductsQuantity"]),
    ...mapGetters("packages", ["activePuHasRequiredFeature"]),
    isTileDisabled() {
      return !this.isTileAuthorized();
    },
    unitName() {
      if (this.productionUnitConvertedUnit && this.isTileAuthorized()) {
        return " - " + ProductionUnitService.getUnitName(this.productionUnitConvertedUnit);
      } else {
        return "";
      }
    },
    // Product Quantity
    primaryValue() {
      return this.allProductsQuantity && this.isTileAuthorized() ? Helpers.round(this.allProductsQuantity, 1) : "—";
    },
  },
  methods: {
    isTileAuthorized() {
      return this.activePuHasRequiredFeature(Tiles.allProductsQuantity.requiredFeature);
    },
  },
};
</script>
