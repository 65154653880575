export default class DayTimeFrameController {
  constructor(vueInstance) {
    this.vue = vueInstance;
  }
  onSelectedTimeFrame() {
    // find the first shift in the coverage that starts on or after the selected date
    // if none found, select the current work shift
    // set the date as the start date of that shift for if the user returns to PRODUCTION_DAY
    this.vue.selectedShiftIndex = this.vue.getFirstCoverageIndexOnOrAfterDate();
    this.vue.selectedHour = null;
    this.vue.selectedRunIndex = null;
  }
  isPreviousTimeFrameDisabled() {
    if (!this.vue.workShiftCoverage) return true; // disable shift navigation if no coverage is provided
    return this.vue.selectedShiftIndex === this.vue.workShiftCoverage.length - 1;
  }
  getDateLabel() {
    return `${this.vue.getDayOfWeek(this.vue.selectedDateTime)} ${this.vue.getDayAndMonth(this.vue.selectedDateTime)}`;
  }
  selectPrevious() {
    // shift coverage is sorted desc (aka. current shift is at index 0)
    // to go to a previous shift, we need to increase the index
    if (!this.vue.workShiftCoverage || this.vue.selectedShiftIndex >= this.vue.workShiftCoverage.length - 1) return;
    this.vue.selectedShiftIndex = this.vue.selectedShiftIndex + 1;
  }
  selectNext() {
    // shift coverage is sorted desc (aka. current shift is at index 0)
    // to go to a previous shift, we need to decrease the index
    if (this.vue.selectedShiftIndex === 0) return;
    this.vue.selectedShiftIndex = this.vue.selectedShiftIndex - 1;
  }
  selectNow() {
    if (!this.vue.workShiftCoverage) return; // navigation disabled if no work shift coverage provided
    this.vue.selectedShiftIndex = 0;
  }
  selectDate() {
    this.vue.selectedShiftIndex = this.vue.getFirstCoverageIndexOnOrAfterDate();
  }
  checkDateTime() {
    // should not have to do anything since if live, the index will be 0
    // if a new work shift was started, it will now appear at the first index
    // of the coverage therefore the selected work shift will update
  }
}
