<!-- Markers are triangular shaped controls -->
<template>
  <section class="timeline-markers-row">
    <timeline-marker-dialog
      v-for="(marker, index) in productMarkers"
      :ref="`marker${index}`"
      :key="`timeline-marker-${index}`"
      :marker="marker"
      :index="index"
      :disabled="isPresenter"
      :selected-marker-id="selectedMarkerId"
      @toggleSelectedMarker="toggleSelectedMarker"
      @toggleMarkerByIndex="toggleMarkerByIndex"
      :coverage-duration="timeline.coverageDuration"
      :next-marker-disabled="isLastMarker(index)"
      :previous-marker-disabled="isFirstMarker(index)"
    >
    </timeline-marker-dialog>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TimelineMarkerDialog from "./dialogs/TimelineMarkerDialog.vue";

export default {
  name: "TimelineMarkers",
  components: {
    TimelineMarkerDialog,
  },
  data() {
    return {
      selectedMarkerId: "",
    };
  },
  computed: {
    ...mapGetters("dashboard", ["productMarkers", "timeline"]),
    ...mapGetters("user", ["isPresenter"]),
  },
  methods: {
    ...mapActions("dashboard", ["fetchProductionUnitProducts"]),
    toggleSelectedMarker(newId) {
      this.fetchProductionUnitProducts();
      this.selectedMarkerId = this.selectedMarkerId !== newId ? newId : "";
    },
    toggleMarkerByIndex(index) {
      this.selectedMarkerId = this.productMarkers[index].event_id;
    },
    leftPosition(blockStart) {
      return 100 / (this.timeline.coverageDuration / blockStart) + "%";
    },
    isLastMarker(index) {
      return this.productMarkers.length - 1 === index;
    },
    isFirstMarker(index) {
      return index === 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline-markers-row {
  flex: 0 0 auto;
  position: relative;
  height: 1.5rem;
  overflow: hidden;
}

.marker {
  position: absolute;
  height: auto;
  min-width: 0;
  bottom: 0;
  padding: 5px;
  background-color: transparent;
  transform: translateX(-50%);
  transition: transform 0.2s ease;

  ::v-deep .v-btn__content {
    opacity: 1 !important;
  }

  .marker-text {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-100%);
    padding-bottom: 4px;
    max-width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    opacity: 0;
    color: currentColor;
    transition: opacity 0.2s linear;
    pointer-events: none;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      width: 100%;
      background-color: currentColor;
      transform: scaleX(0);
      transition: transform 0.2s ease;
    }
  }

  &.active-marker {
    transform: translateY(-3px) translateX(-50%);
    z-index: 1;

    .marker-pointer {
      border-top: 12px solid var(--color-primary);
    }

    .marker-text {
      opacity: 1;
      pointer-events: auto;

      &:after {
        background-color: var(--color-primary);
        transform: scaleX(1);
      }
    }
  }
}
</style>
