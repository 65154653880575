<template>
  <v-form :wxid="$options.name" ref="productSelectionForm" class="mt-6" lazy-validation :disabled="isDeletedProduct">
    <fieldset>
      <v-row>
        <v-col class="d-flex">
          <legend class="wx-typo-h2 mr-2">
            {{ $t("dashboard.productionRun.newProductionRunBtn") }}
          </legend>
          <wx-contextualized-help
            :help-card-title="$t('dashboard.productionRun.productionRunInformationTooltip.title')"
            :max-width=320
            is-help-card
          >
            <template v-slot:help-card-text-slot>
              <p>
                {{ $t("dashboard.productionRun.productionRunInformationTooltip.text") }}
              </p>
              <p class="mb-0">
                <a
                  :href="$t('dashboard.productionRun.productionRunInformationTooltip.academyLink.href')"
                  :title="$t('dashboard.productionRun.productionRunInformationTooltip.academyLink.hint')"
                  target="productionRun"
                >
                  {{ $t("dashboard.productionRun.productionRunInformationTooltip.academyLink.text") }}
                </a>
              </p>
            </template>
          </wx-contextualized-help>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="field-col">
          <wx-autocomplete
            v-model="selectedProduct"
            :items="productItems"
            :label="$t('dashboard.productionRun.selectorLabel')"
            :disabled="isDeletedProduct"
          />
        </v-col>
        <v-col cols="12" class="field-col">
          <!-- - - - - - - - - - - -       Work Order       - - - - - - - - - - - - - - -->
          <wx-text-field
            v-model="workOrder"
            :label="$t('dashboard.productionRun.workOrder')"
            :disabled="isNullProduct"
            :rules="[() => validateWorkOrder()]"
          />
        </v-col>
        <v-col cols="12" class="field-col">
          <!-- - - - - - - - - - - -       Lot Number       - - - - - - - - - - - - - - -->
          <wx-text-field
            v-model="lotNumber"
            :label="$t('dashboard.productionRun.lotNumber')"
            :disabled="isNullProduct"
            :rules="[() => validateLotNumber()]"
          />
        </v-col>
      </v-row>
      <p class="text-subtitle-2 font-weight-light mt-2">{{ $t("dashboard.productionRun.plannedQuantityHint") }}</p>
      <v-row>
        <v-col cols="12" class="field-col">
          <!-- - - - - - - - - - - -     Planned Qty      - - - - - - - - - - - - - -->
          <wx-text-field
            v-model.number="plannedQuantity"
            :label="$t('dashboard.productionRun.plannedQuantity')"
            :disabled="isNullProduct"
            :rules="[() => validatePlannedQuantity()]"
            min="0"
          />
        </v-col>
        <v-col cols="12" class="field-col">
          <!-- - - - - - - - - -      Number Of Employees      - - - - - - - - - - - - -->
          <wx-text-field
            v-model="numberOfEmployees"
            :label="$t('dashboard.productionRun.numberOfEmployees')"
            :disabled="isNullProduct"
            :rules="[() => validateNumberOfEmployees()]"
            min="0"
          />
        </v-col>
        <v-col cols="12" class="field-col">
          <!-- - - - - - - - - -      Retroactive?      - - - - - - - - - - - - -->
          <v-checkbox
            v-model="retroactiveSelection"
            :label="$t('dashboard.productionRun.retroactiveLabel')"
            class="mt-0"
          />
        </v-col>
      </v-row>
      <v-row v-if="retroactiveSelection" :disabled="isDeletedProduct">
        <v-col cols="12" sm="7" class="field-col">
          <wx-simple-calendar-date-picker
            v-model="effectiveStartDate"
            :label="$t('dashboard.productionRun.effectiveStartDate')"
            :timezone="getTimezone()"
            :limit-past-number-of-days="30"
            :rules="[() => validateAndSetEffectiveDates()]"
            :error-messages="effectiveStartDateMessages"
          />
        </v-col>
        <v-col cols="12" sm="5" class="field-col">
          <wx-time-picker
            v-model="effectiveStartTime"
            :title="$t('dashboard.productionRun.effectiveStartTime')"
            :rules="[() => validateAndSetEffectiveDates()]"
            :error-messages="effectiveStartTimeMessages"
            :show-prepend-icon="false"
          />
        </v-col>
      </v-row>
    </fieldset>
    <fieldset class="form-footer-actions mt-4">
      <wx-btn-standard @click="emitClose" :title="$t('common.dialogFormCancelHoverTitle')" outlined>
        {{ $t("common.cancel") }}
      </wx-btn-standard>
      <wx-btn-standard
        id="pendo-submit-production-run-btn"
        v-if="!isDeletedProduct"
        @click="emitSubmit"
        :title="$t('common.formSubmitHoverTitle')"
        color="primary"
      >
        {{ $t("common.submit") }}
      </wx-btn-standard>
    </fieldset>
  </v-form>
</template>

<script>
import WxAutocomplete from "@/components/ui/WxAutocomplete.vue";
import WxTextField from "@/components/ui/WxTextField.vue";
import WxSimpleCalendarDatePicker from "@/components/ui/WxSimpleCalendarDatePicker.vue";
import WxTimePicker from "@/components/ui/WxTimePicker.vue";
import WxBtnStandard from "@/components/ui/WxBtnStandard.vue";
import WxContextualizedHelp from "@/components/ui/WxContextualizedHelp.vue";
import moment from "moment-timezone";
import * as TimeUtils from "@/store/TimeUtils";
import { DateTime } from "luxon";
import { mapActions, mapGetters } from "vuex";

function isValidDecimalGreaterThan(valueAsString, minValueExcluded) {
  let value = Number.parseFloat(valueAsString);
  if (isNaN(value)) {
    return false;
  } else {
    if (value <= minValueExcluded) {
      return false;
    }
  }
  return true;
}

export default {
  name: "ProductionRunForm",
  components: {
    WxBtnStandard,
    WxTimePicker,
    WxSimpleCalendarDatePicker,
    WxTextField,
    WxAutocomplete,
    WxContextualizedHelp,
  },
  props: {
    initialProductionRun: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      selectedProduct: "no-product",
      plannedQuantity: null,
      numberOfEmployees: null,
      workOrder: null,
      lotNumber: null,
      retroactiveSelection: false,
      effectiveStart: null,
      effectiveStartDate: null,
      effectiveStartDateMessages: [],
      effectiveStartTime: null,
      effectiveStartTimeMessages: [],

      maxWorkOrderLength: 100, // constant
      maxLotNumberLength: 100, // constant
    };
  },
  watch: {
    initialProductionRun(current, previous) {
      if (current.event_id !== previous.event_id) this.initializeFields();
    },
    isNullProduct() {
      if (this.isNullProduct) {
        this.plannedQuantity = null;
        this.numberOfEmployees = null;
        this.workOrder = null;
        this.lotNumber = null;
      } else if (!this.initialProductionRun) {
        this.numberOfEmployees = 1;
      } else {
        this.numberOfEmployees = this.initialProductionRun.production_properties.number_of_employees;
      }
    },
    retroactiveSelection() {
      if (!this.retroactiveSelection) {
        this.initializeStartDateTime(null);
      }
    },
  },
  computed: {
    ...mapGetters("dashboard", ["productionUnitProducts"]),
    ...mapGetters("navigation", ["activeFactory"]),
    isNullProduct() {
      return !this.selectedProduct || this.selectedProduct === "no-product";
    },
    isDeletedProduct() {
      const isProductDeleted = !this.productionUnitProducts.some((p) => p.sku === this.selectedProduct);

      if (isProductDeleted && !this.isNullProduct) {
        this.showOperationWarning(this.$t("dashboard.productionRun.errors.productDeletedForProductionEdit", { sku: this.selectedProduct }),);
        return true;
      }
      return false;
    },
    productItems() {
      const items = this.productionUnitProducts.map((p) => ({
        text: this.getProductDisplayName(p),
        value: p.sku,
      }));
      const noSelection = [{ text: this.$t("dashboard.panelHeader.unspecifiedProduct"), value: "no-product" }];
      const products = noSelection.concat(items);
      const productSelected = [{ text: this.selectedProduct, value: this.selectedProduct }];
      if (!items.some((p) => p.value === this.selectedProduct)) {
        return products.concat(productSelected);
      }
      return products;
    },
  },
  methods: {
    ...mapActions("dashboard", ["fetchProductionUnitProducts"]),
    ...mapActions("operation", ["showOperationWarning"]),
    initializeFields() {
      if (!this.initialProductionRun) {
        this.selectedProduct = "no-product";
        this.workOrder = null;
        this.lotNumber = null;
        this.plannedQuantity = null;
        this.numberOfEmployees = null;
        this.retroactiveSelection = false;
        this.initializeStartDateTime(null);
      } else {
        this.selectedProduct = this.initialProductionRun.production_properties.sku
          ? this.initialProductionRun.production_properties.sku
          : "no-product";
        this.workOrder =
          this.selectedProduct === "no-product"
            ? null
            : this.initialProductionRun.production_properties.work_order_label;
        this.lotNumber =
          this.selectedProduct === "no-product" ? null : this.initialProductionRun.production_properties.lot_label;
        this.plannedQuantity = this.initialProductionRun.production_properties.planned_quantity;
        this.numberOfEmployees = this.initialProductionRun.production_properties.number_of_employees;
        this.retroactiveSelection = true;
        this.initializeStartDateTime(this.initialProductionRun.original_start);
      }
    },
    initializeStartDateTime(effectiveStart) {
      this.effectiveStartDate = this.getDefaultEffectiveStartDate(effectiveStart);
      this.effectiveStartTime = this.getDefaultEffectiveStartTime(effectiveStart);
    },
    getProductDisplayName(product) {
      let productDescriptionString = product.description ? " (" + product.description + ")" : "";
      return product.sku + productDescriptionString;
    },
    getDefaultEffectiveStartDate(millisUtc) {
      if (!millisUtc) return TimeUtils.getTodayDate(this.getTimezone());
      return TimeUtils.getDateFromMillis(millisUtc, this.getTimezone());
    },
    getDefaultEffectiveStartTime(millisUtc) {
      if (!millisUtc) return TimeUtils.getCurrentTime(this.getTimezone());
      return TimeUtils.getTimeFromMillis(millisUtc, this.getTimezone());
    },
    getTimezone() {
      if (this.activeFactory && this.activeFactory.timezone) {
        return this.activeFactory.timezone;
      } else {
        const zone = moment.tz.guess(true);
        return zone === null || zone === undefined ? "America/Montreal" : zone;
      }
    },
    validatePlannedQuantity() {
      if (this.plannedQuantity && String(this.plannedQuantity).length > 0) {
        if (!isValidDecimalGreaterThan(this.plannedQuantity, 0.0)) {
          return this.$t("dashboard.productionRun.errors.invalidPlannedQuantity");
        }
      }
      return true;
    },
    validateWorkOrder() {
      if (this.workOrder && this.workOrder.length > this.maxWorkOrderLength) {
        return this.$t("dashboard.productionRun.errors.workOrderTooLong", { maxLength: this.maxWorkOrderLength });
      }
      return true;
    },
    validateLotNumber() {
      if (this.lotNumber && this.lotNumber.length > this.maxLotNumberLength) {
        return this.$t("dashboard.productionRun.errors.lotNumberTooLong", { maxLength: this.maxLotNumberLength });
      }
      return true;
    },
    convertToDottedDecimal(value) {
      if (value) {
        let valueAsString = String(value);
        if (valueAsString.indexOf(",") >= 0) {
          return Number(valueAsString.replace(",", "."));
        } else {
          return Number(valueAsString);
        }
      }
      return null;
    },
    validateNumberOfEmployees() {
      if (this.numberOfEmployees) {
        const nbEmployees = this.convertToDottedDecimal(this.numberOfEmployees);
        if (isNaN(nbEmployees)) {
          return this.$t("dashboard.productionRun.errors.invalidNumberOfEmployees");
        }
        if (!isValidDecimalGreaterThan(nbEmployees, 0.0)) {
          return this.$t("dashboard.productionRun.errors.invalidNumberOfEmployees");
        }
      }
      return true;
    },
    resetDateValidations() {
      this.effectiveStartDateMessages = [];
      this.effectiveStartTimeMessages = [];
    },
    validateAndSetEffectiveDates() {
      let start = null;
      this.resetDateValidations();
      if (this.retroactiveSelection) {
        let startDate = null;
        let startTime = null;

        if (this.effectiveStartDate) {
          startDate = DateTime.fromFormat(this.effectiveStartDate, TimeUtils.DATE_FORMAT);
          if (!startDate.isValid) {
            this.effectiveStartDateMessages = [this.$t("dashboard.productionRun.errors.invalidDate")];
            return false;
          }
        } else {
          this.effectiveStartDateMessages = [this.$t("dashboard.productionRun.errors.dateMissing")];
          return false;
        }
        if (this.effectiveStartTime) {
          startTime = DateTime.fromFormat(this.effectiveStartTime, TimeUtils.TIME_FORMAT);
          if (!startTime.isValid) {
            this.effectiveStartTimeMessages = [this.$t("dashboard.productionRun.errors.invalidTime")];
            return false;
          }
        } else {
          this.effectiveStartTimeMessages = [this.$t("dashboard.productionRun.errors.timeMissing")];
          return false;
        }

        // At this point, the Start date & time are valid
        start = startDate.set({ hour: startTime.hour, minute: startTime.minute, second: 0, millisecond: 0 });

        // Is start in the future?
        const now = DateTime.now().set({ second: 0, millisecond: 0 });
        if (start > now) {
          this.effectiveStartDateMessages = [this.$t("dashboard.productionRun.errors.dateInFuture")];
          this.effectiveStartTimeMessages = [this.$t("dashboard.productionRun.errors.dateInFuture")];
          return false;
        }

        // OK. Start is set in the past. Does it exceed the limit?
        const pastLimit = now.minus({ days: 30 });
        if (start < pastLimit) {
          this.effectiveStartDateMessages = [this.$t("dashboard.productionRun.errors.pastLimitDate")];
          this.effectiveStartTimeMessages = [this.$t("dashboard.productionRun.errors.pastLimitDate")];
          return false;
        }

        // OK. Start date has been accepted
        // We are good to go with these dates!
      }
      this.effectiveStart = start ? start.toISO() : DateTime.now().toISO();
      this.$refs.productSelectionForm.resetValidation();
      return true;
    },
    emitClose() {
      this.$emit("close");
    },
    emitSubmit() {
      // make sure the form is valid before submitting
      if (!this.$refs.productSelectionForm.validate() || !this.validateAndSetEffectiveDates()) return;

      const submission = {
        eventId: this.initialProductionRun ? this.initialProductionRun.event_id : null,
        sku: this.selectedProduct === "no-product" ? null : this.selectedProduct,
        workOrder: this.workOrder,
        lotNumber: this.lotNumber,
        plannedQuantity: this.plannedQuantity,
        numberOfEmployees: this.convertToDottedDecimal(this.numberOfEmployees),
        startTime: this.effectiveStart,
      };
      this.$emit("submit", submission);
    },
    reset() {
      this.$refs.productSelectionForm.resetValidation();
      this.resetDateValidations();
      this.initializeFields();
    },
  },
  mounted() {
    this.fetchProductionUnitProducts();
    this.$refs.productSelectionForm.resetValidation();
    this.resetDateValidations();
    this.initializeFields();
  },
};
</script>

<style lang="scss" scoped>
// matching the style of `ui/WxContextualizedHelp.vue`
.wx-contextualized-help {
  &__activator {
    font-size: var(--font-size-h3);
  }
}

.inverted-theme-card {
  &.v-card {
    background-color: var(--color-flat-panel-theme);
    color: var(--color-text-theme);
    opacity: 1;

    .v-card__text {
      color: var(--color-text-theme);
    }
  }
}

::v-deep .v-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-base-theme);

  &__content {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    position: initial;
    left: auto;
    width: 100%;
    max-width: 500px;
    padding-inline: var(--grid-gutter);
    pointer-events: auto;

    header {
      padding-top: var(--grid-gutter);

      .close-btn {
        position: fixed;
        z-index: 1; // hover fields
        top: var(--dialog-close-offset);
        right: var(--dialog-close-offset);
      }
    }
    .v-form {
      width: 100%;

      fieldset {
        border: none;

        &.form-footer-actions {
          display: flex;
          justify-content: flex-end;
          column-gap: var(--btn-inline-margin);
          padding-bottom: var(--grid-gutter);
        }
      }

      // Responsive Columns
      .row {
        margin-top: 0;
        margin-bottom: 0;
        .field-col {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }
}
</style>
