<template>
  <v-card :wxid="$options.name" class="tile disconnected" tag="section">
    <v-btn @click="openDialog" class="tile-inner" depressed>
      <h3 :title="$t('tiles.disconnected')" class="primary-title wx-typo-h3">
        {{ $t("tiles.disconnected") }}
      </h3>
      <h2 class="primary-value">{{ primaryTileValue }}</h2>

      <tile-disconnected-dialog v-model="dialogState" @closeDialog="closeDialog" />
    </v-btn>
  </v-card>
</template>

<script>
import TileBase from "@/components/dashboard/tiles/TileBase";
import TileDisconnectedDialog from "@/components/dashboard/tiles/TileDisconnectedDialog";
import { mapGetters } from "vuex";
import { Duration } from "luxon";

export default {
  name: "TileDisconnected",
  components: { TileDisconnectedDialog },
  extends: TileBase,
  inheritAttrs: false,
  data() {
    return {
      dialogState: false,
      alertDurationRefreshInterval: null,
      alertDurationMillis: 0,
    };
  },
  computed: {
    ...mapGetters("dashboard", ["oldestDataSourceAlert"]),
    primaryTileValue() {
      return Duration.fromMillis(this.alertDurationMillis).toFormat("hh:mm:ss");
    },
  },
  watch: {
    oldestDataSourceAlert() {
      this.handleDataSourceAlert();
    },
  },
  methods: {
    openDialog() {
      this.dialogState = true;
    },
    closeDialog() {
      this.dialogState = false;
    },
    handleDataSourceAlert() {
      clearInterval(this.alertDurationRefreshInterval);
      this.alertDurationMillis = 0;

      if (this.oldestDataSourceAlert) {
        this.alertDurationRefreshInterval = setInterval(() => {
          this.alertDurationMillis = Date.now() - this.oldestDataSourceAlert.inactive_since;
        }, 1000);
      }
    },
  },
  mounted() {
    this.handleDataSourceAlert();
  },
};
</script>
