<template>
  <v-card
    :wxid="$options.name"
    :class="[{ disabled: isTileDisabled }, targetStatusClass]"
    class="tile product-giveaway percent-value status-pictogram"
    tag="section"
    sf="4"
  >
    <grouped-tile-selector-menu :tile-index="tileIndex" />
    <div class="tile-inner">
      <h3 :title="$t('tiles.productGiveawayPercent')" class="primary-title">
        {{ $t("tiles.productGiveawayPercent") }}
        <v-icon v-if="!isTileAuthorized()">mdi-lock</v-icon>
      </h3>
      <h2 class="primary-value">{{ primaryValue }}</h2>
      <p class="value-sub-text__product-note">({{ $t("tiles.currentProductNote") }})</p>
      <section class="status-details">
        <dl>
          <dt>{{ $t("dashboard.targetInfo.product") }}</dt>
          <dd>
            <v-icon small>mdi-target</v-icon>
            {{ targetValue }}
          </dd>
        </dl>
        <div class="pictogram" />
      </section>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import GroupedTileSelectorMenu from "@/components/dashboard/tiles/GroupedTileSelectorMenu";
import TileBase from "@/components/dashboard/tiles/TileBase";
import Tiles from "@/components/Tiles";
import Helpers from "@/helpers";

export default {
  name: "TileProductGiveawayPercent",
  extends: TileBase,
  inheritAttrs: false,
  components: { GroupedTileSelectorMenu },
  computed: {
    ...mapGetters("dashboard", ["productGiveaway"]),
    ...mapGetters("packages", ["activePuHasRequiredFeature"]),
    isTileDisabled() {
      return !this.isTileAuthorized();
    },
    // Product Giveaway Percent
    primaryValue() {
      return Helpers.getRoundedValueOrZeroOrDash(this.productGiveaway.valuePercent, this.isTileAuthorized());
    },
    targetValue() {
      return Helpers.getRoundedPercentageOrDash(this.productGiveaway.targetPercent);
    },
    targetStatusClass() {
      let statusClass;
      if (
        this.productGiveaway.targetPercent !== null &&
        this.productGiveaway.targetPercent !== undefined &&
        this.productGiveaway.valuePercent !== null &&
        this.productGiveaway.valuePercent !== undefined
      ) {
        const value = Math.abs(this.productGiveaway.valuePercent) - this.productGiveaway.targetPercent; // Giveaway can be negative with new version
        if (value <= 0) {
          statusClass = "success-state";
        } else if (value <= 1) {
          statusClass = "warning-state";
        } else if (value > 1) {
          statusClass = "error-state";
        }
      }
      return statusClass;
    },
  },
  methods: {
    isTileAuthorized() {
      return this.activePuHasRequiredFeature(Tiles.productGiveawayPercent.requiredFeature);
    },
  },
};
</script>
