<!-- Markers are triangular shaped controls -->
<template :wxid="$options.name">
  <v-menu
    v-model="isMarkerActive"
    :close-on-content-click="false"
    :left="isMarkerInTheLastThirdOfTheChart"
    :nudge-left="isMarkerInTheLastThirdOfTheChart ? 30 : null"
    :nudge-right="!isMarkerInTheLastThirdOfTheChart ? 10 : null"
    :content-class="
      isMarkerInTheLastThirdOfTheChart ? `marker-dialog marker-dialog--left` : `marker-dialog marker-dialog--right`
    "
    min-width="160"
    rounded="lg"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        @click="toggleSelectedMarker"
        :class="{ 'marker-btn--active': isMarkerActive }"
        :style="{ left: leftPosition(marker.start) }"
        :ripple="false"
        class="marker-btn"
        depressed
        plain
      >
        <span class="marker-btn__caret" />
      </v-btn>
    </template>

    <v-card tag="article" class="timeline-marker-card px-2" rounded="lg">
      <v-card-title tag="header" class="px-0 pb-3">
        <h4 :title="productLabel" class="heading wx-typo-sm text-truncate">
          {{ productLabel }}
        </h4>
        <v-btn-toggle class="justify-space-between">
          <wx-btn-icon
            :title="$t('common.previous')"
            :disabled="previousMarkerDisabled"
            color="primary"
            class="nav-btn nav-btn--left"
            @click="togglePreviousMarker"
            small
            text
          >
            <v-icon>mdi-chevron-left</v-icon>
          </wx-btn-icon>
          <wx-btn-icon
            :title="$t('common.next')"
            :disabled="nextMarkerDisabled"
            color="primary"
            class="nav-btn nav-btn--right"
            @click="toggleNextMarker"
            small
            text
          >
            <v-icon>mdi-chevron-right</v-icon>
          </wx-btn-icon>
        </v-btn-toggle>
      </v-card-title>
      <v-card-text class="px-2 pb-2">
        <dl v-if="marker.production_properties.planned_quantity" class="data-report">
          <dt class="label">{{ `${$t("dashboard.productionRun.tooltip.plannedQuantity_abbr")}` }}</dt>
          <dd class="data">{{ marker.production_properties.planned_quantity }}</dd>
        </dl>
        <dl v-if="marker.production_properties.work_order_label" class="data-report">
          <dt class="label">{{ `${$t("dashboard.productionRun.tooltip.workOrder_abbr")}` }}</dt>
          <dd class="data">{{ marker.production_properties.work_order_label }}</dd>
        </dl>
        <dl v-if="marker.production_properties.lot_label" class="data-report">
          <dt class="label">{{ `${$t("dashboard.productionRun.tooltip.lot")}` }}</dt>
          <dd class="data">{{ marker.production_properties.lot_label }}</dd>
        </dl>
        <dl v-if="marker.production_properties.number_of_employees" class="data-report">
          <dt class="label">{{ `${$t("dashboard.productionRun.tooltip.employees_abbr")}` }}</dt>
          <dd class="data">{{ marker.production_properties.number_of_employees }}</dd>
        </dl>
        <dl class="data-report pt-1">
          <dt class="label icon-as-label">
            <v-icon>mdi-timer-outline</v-icon>
          </dt>
          <dd class="data">
            <time v-if="isMultiLine">
              {{ startDateLabel }}
              <fragment v-if="endDateLabel">
                <br />
                {{ endDateLabel }}
              </fragment>
            </time>
            <time v-else>
              {{ combinedDateLabels }}
            </time>
          </dd>
        </dl>
      </v-card-text>
      <v-card-actions tag="footer" class="flex-small-column-gap">
        <modify-production-run-dialog
          v-if="isMarkerActive && !isPresenter"
          :production-run="marker"
          ref="productSelectionDialog"
          @close="toggleTooltip"
        />
        <dialog-confirm-deletion
          v-if="!isPresenter"
          @cancel="cancelDeletion"
          @confirm="submitDeletion"
          :confirm-text-prefix="$t('dashboard.productionRun.deleteProductionRun.questionConfirmToDelete')"
          :element-display-name="productLabelNoParentheses"
          :info-box-text="$t('dashboard.productionRun.deleteProductionRun.deletionDescription')"
          :delete-button-text="$t('dashboard.productionRun.deleteProductionRun.deleteBtn')"
          :as-text-button="true"
          activator-btn-color="error"
          activator-btn-class="delete-production-run px-0 ml-1"
          small-activator-btn
          plain-text-activator-btn
        />
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import ModifyProductionRunDialog from "@/components/dashboard/productionRun/ModifyProductionRunDialog.vue";
import DialogConfirmDeletion from "@/components/DialogConfirmDeletion.vue";
import { mapGetters, mapActions } from "vuex";
import { DateTime } from "luxon";
import * as TimeUtils from "@/store/TimeUtils";
import { DATE_FORMAT, DATE_TIME_FORMAT_NO_SECONDS, TIME_FORMAT, DATE_TIME_FORMAT } from "@/store/TimeUtils";
import WxBtnIcon from "@/components/ui/WxBtnIcon.vue";

export default {
  name: "TimelineMarkerDialog",
  components: {
    ModifyProductionRunDialog,
    WxBtnIcon,
    DialogConfirmDeletion,
  },
  data() {
    return {
      isMarkerActive: false,
      closeButtonPressed: true,
      fav: true,
      menu: true,
      message: true,
      hints: true,
      dateTimes: null,
      currentProduct: null,
      showModal: false,
    };
  },
  props: {
    coverageDuration: {
      type: Number,
      default: null,
    },
    marker: {
      type: Object,
      default: () => null,
    },
    index: {
      type: Number,
      required: true,
    },
    selectedMarkerId: {
      type: String,
      default: "",
    },
    nextMarkerDisabled: {
      type: Boolean,
      required: true,
    },
    previousMarkerDisabled: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters("dashboard", ["productionUnitProducts", "coverage"]),
    ...mapGetters("navigation", ["activeFactory"]),
    ...mapGetters("user", ["isPresenter"]),
    isMarkerInTheLastThirdOfTheChart() {
      if (!this.coverageDuration) return false;
      const markerStart = parseFloat(this.marker.start);
      return markerStart / this.coverageDuration > 2.0 / 3.0;
    },
    productLabel() {
      return this.buildProductLabel(true);
    },
    productLabelNoParentheses() {
      return this.buildProductLabel(false);
    },
    productSku() {
      if (this.product_selection) {
        return this.product_selection.sku;
      } else {
        return this.marker.label;
      }
    },
    startMs() {
      const marker = this.marker;
      return !marker.original_start ? DateTime.now().toMillis() : marker.original_start;
    },
    endMs() {
      const marker = this.marker;
      return !marker.original_end ? DateTime.now().toMillis() : marker.original_end;
    },
    isMultiLine() {
      const startDate = DateTime.fromMillis(this.startMs);
      const endDate = DateTime.fromMillis(this.endMs);
      return startDate.toFormat(TimeUtils.DATE_FORMAT) !== endDate.toFormat(TimeUtils.DATE_FORMAT);
    },
    combinedDateLabels() {
      const start = this.millisToString(this.startMs, this.timeLabelFormat);
      const end = this.millisToString(this.endMs, this.timeLabelFormat);
      return `${start} — ${this.marker.original_end ? end : this.$t("dashboard.productionRun.inProduction")}`;
    },
    startDateLabel() {
      return this.millisToString(this.startMs, this.timeLabelFormat);
    },
    endDateLabel() {
      return this.marker.original_end
        ? this.millisToString(this.endMs, this.timeLabelFormat)
        : this.$t("dashboard.productionRun.inProduction");
    },
    timeLabelFormat() {
      return this.isMultiLine ? DATE_TIME_FORMAT_NO_SECONDS : TIME_FORMAT;
    },
    startDateTime() {
      return this.millisToDateAndTime(this.startMs);
    },
    endDateTime() {
      return !this.marker.original_end ? null : this.millisToDateAndTime(this.marker.original_end);
    },
  },
  watch: {
    selectedMarkerId() {
      if (this.selectedMarkerId !== this.marker.event_id) {
        this.isMarkerActive = false;
      } else {
        this.isMarkerActive = true;
      }
    },
    marker: {
      immediate: true,
      deep: true,
      handler() {
        this.setDateTimes();
        this.setCurrentProduct();
      },
    },
    productionUnitProducts: {
      immediate: true,
      deep: true,
      handler() {
        this.setCurrentProduct();
      },
    },
  },
  methods: {
    ...mapActions("dashboard", ["deleteProductionRun"]),
    buildProductLabel(useParentheses) {
      const sku = this.productSku;
      if (sku) {
        let description = "";
        if (this.productionUnitProducts) {
          const product = this.findProduct(sku);
          if (product?.description) {
            description = useParentheses ? " (" + product.description + ")" : product.description;
          }
        }
        return `${sku} ${description}`;
      } else {
        return this.$t("product.listing.noData");
      }
    },
    setDateTimes() {
      this.dateTimes = {
        startDateTime: this.startDateTime,
        endDateTime: this.endDateTime,
      };
    },
    setCurrentProduct() {
      if (this.productionUnitProducts) {
        const sku = this.productSku;
        this.currentProduct = this.findProduct(sku);
      }
    },
    millisToDateAndTime(millis) {
      const date = this.millisToDate(millis);
      const time = this.millisToTime(millis);
      const dateTime = this.millisToDateTime(millis);
      return {
        date: date,
        time: time,
        dateTime: dateTime,
      };
    },
    cancelDeletion() {
      this.closeDeletionDialog();
    },
    closeDeletionDialog() {
      this.showModal = false;
    },
    submitDeletion() {
      const sku = this.productSku;
      const eventId = this.marker?.event_id;
      const deletionRequest = {
        sku: sku,
        productionUnitEventId: eventId,
      };

      this.deleteProductionRun(deletionRequest).then(() => this.closeDeletionDialog());
      this.showModal = false;
    },
    toggleTooltip() {
      this.isMarkerActive = !this.isMarkerActive;
    },
    close() {
      this.closeButtonPressed = true;
    },
    toggleSelectedMarker() {
      this.toggleTooltip();
      this.$emit("toggleSelectedMarker", this.marker.event_id);
    },
    togglePreviousMarker() {
      this.toggleTooltip();
      this.$emit("toggleMarkerByIndex", this.index - 1);
    },
    toggleNextMarker() {
      this.toggleTooltip();
      this.$emit("toggleMarkerByIndex", this.index + 1);
    },
    leftPosition(blockStart) {
      return 100 / (this.coverageDuration / blockStart) + "%";
    },
    findProduct(sku) {
      return this.productionUnitProducts.find((p) => p.sku === sku);
    },
    toMillis(date) {
      return TimeUtils.toEpochMillisUTC(date);
    },
    millisToString(date, format) {
      return TimeUtils.fromEpochMillis(date, this.activeFactory.timezone, format);
    },
    millisToDate(millis) {
      return TimeUtils.fromEpochMillis(millis, this.activeFactory.timezone, DATE_FORMAT);
    },
    millisToTime(millis) {
      return TimeUtils.fromEpochMillis(millis, this.activeFactory.timezone, TIME_FORMAT);
    },
    millisToDateTime(millis) {
      return TimeUtils.fromEpochMillis(millis, this.activeFactory.timezone, DATE_TIME_FORMAT);
    },
  },
};
</script>

<style lang="scss" scoped>
// local var
$caretSize: 8px;

// style
.v-application {
  .marker-dialog {
    transform: translateY(-25%);
    z-index: 2;

    &--right {
      margin-left: (($caretSize * 2.5) - 1px);
    }
  }
}

.timeline-marker-card {
  &.v-card {
    min-width: 13em;
    background-color: var(--color-element-layer1);
    color: var(--color-text-theme);
    opacity: 1;

    .v-card {
      &__title {
        justify-content: space-between;

        .v-btn-toggle {
          max-width: 30%;
          .nav-btn {
            max-width: 50%;
          }
        }
        .heading {
          width: 70%;
          font-weight: 700;
          text-align: center;
        }
      }
      &__text {
        color: var(--color-text-theme);

        .data-report {
          display: flex;
          column-gap: 0.5em;
          margin-bottom: 0.5em;
          font-size: var(--font-size-sm);

          .label,
          .icon-as-label,
          .data {
            line-height: 1.35;
          }
          .label,
          .data {
            color: currentcolor;
          }

          .label,
          .icon-as-label {
            width: 5em;
            text-align: left;
            margin-right: 0;
          }
          .label {
            word-break: break-word;
            opacity: 0.8;

            &:not(.icon-as-label):after {
              content: " :";
            }
          }
          .icon-as-label {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            padding-right: 0.25rem;
            opacity: 0.7;

            .v-icon {
              font-size: 1.15em;
              color: currentcolor;
            }
          }
          .data {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            width: calc(100% - 5em);

            time {
              text-align: right;
            }
          }
        }
      }
    }
  }
}

.marker-btn {
  &.v-btn {
    position: absolute;
    z-index: 1;
    height: auto;
    min-width: 0;
    top: ($caretSize - 5px);
    padding: 2px;
    background-color: transparent;
    transform: translateX(-50%);
    transition: transform 0.3s ease;
    border-radius: 0;

    ::v-deep .v-btn__content {
      opacity: 1 !important;
    }
    &:hover,
    &:focus,
    &--active {
      z-index: 3;
      padding-top: 1px;
      border-top: 2px solid var(--color-primary);
    }
  }
  // triangle shape
  &__caret {
    display: block;
    width: 0;
    height: 0;
    border-top: $caretSize solid var(--color-inverted-container-theme);
    border-right: $caretSize solid transparent;
    border-left: $caretSize solid transparent;
    transition: border-color 0.2s linear;

    @at-root .marker-btn--active #{&} {
      border-top-color: var(--color-primary);
    }
  }
}
</style>
