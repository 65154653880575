<template>
  <v-card
    :wxid="$options.name"
    :class="{ disabled: isTileDisabled }"
    class="tile reject-quantity secondary-heading"
    tag="section"
    sf="3"
  >
    <grouped-tile-selector-menu :tile-index="tileIndex" />
    <div class="tile-inner">
      <h3 :title="$t('tiles.netQuantity.name_unit', { unit: unitName })" class="primary-title">
        {{ $t("tiles.netQuantity.name_unit", { unit: unitName }) }}
        <v-icon v-if="!isTileAuthorized()">mdi-lock</v-icon>
      </h3>
      <section class="primary-value mt-1 d-flex flex-small-column-gap align-center">
        <span class="wx-typo-h1">{{ netQuantityValue }}</span>
        <v-tooltip v-if="isNegativeGoods" max-width="300" top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-icon color="warning">$alertIcon</v-icon>
            </div>
          </template>
          <span>{{ $t("tiles.netQuantity.negativeNetQuantityWarning") }}</span>
        </v-tooltip>
      </section>

      <template>
        <h3 class="secondary-title">{{ $t("tiles.rejectQuantity_unit", { unit: unitName }) }}</h3>
        <h2 class="secondary-value">{{ RejectValue }}</h2>
      </template>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ProductionUnitService from "@/components/productionunit/ProductionUnitService";
import GroupedTileSelectorMenu from "@/components/dashboard/tiles/GroupedTileSelectorMenu";
import TileBase from "@/components/dashboard/tiles/TileBase";
import Tiles from "@/components/Tiles";
import Helpers from "@/helpers";

export default {
  name: "TileRejectQuantity",
  extends: TileBase,
  inheritAttrs: false,
  components: { GroupedTileSelectorMenu },
  computed: {
    ...mapGetters("dashboard", ["productionUnitConvertedUnit", "rejectQuantity", "allProductsQuantity"]),
    ...mapGetters("packages", ["activePuHasRequiredFeature"]),
    isTileDisabled() {
      return !this.isTileAuthorized();
    },
    unitName() {
      if (this.productionUnitConvertedUnit && this.isTileAuthorized()) {
        return " - " + ProductionUnitService.getUnitName(this.productionUnitConvertedUnit);
      } else {
        return "";
      }
    },
    // All Production Quantity minus Rejects
    netQuantityValue() {
      if (!this.isTileAuthorized()) return "—";
      const rejects = this.rejectQuantity ? this.rejectQuantity : 0;
      return this.allProductsQuantity ? Helpers.round(this.allProductsQuantity - rejects, 1) : "—";
    },

    // Reject Quantity
    RejectValue() {
      if (this.rejectQuantity === null || this.rejectQuantity === undefined || !this.isTileAuthorized()) return "-";
      return Helpers.round(this.rejectQuantity, 1);
    },

    isNegativeGoods() {
      return this.netQuantityValue < 0;
    },
  },
  methods: {
    isTileAuthorized() {
      return this.activePuHasRequiredFeature(Tiles.rejectQuantity.requiredFeature);
    },
  },
};
</script>
