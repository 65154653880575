import { render, staticRenderFns } from "./WxTimeFrameDatePicker.vue?vue&type=template&id=f4cfb962&scoped=true"
import script from "./WxTimeFrameDatePicker.vue?vue&type=script&lang=js"
export * from "./WxTimeFrameDatePicker.vue?vue&type=script&lang=js"
import style0 from "./WxTimeFrameDatePicker.vue?vue&type=style&index=0&id=f4cfb962&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4cfb962",
  null
  
)

export default component.exports