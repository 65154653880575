<template>
  <v-card :wxid="$options.name" :class="{ disabled: isTileDisabled }" class="tile production" tag="section" sf="1">
    <grouped-tile-selector-menu :tile-index="tileIndex" />
    <div class="tile-inner">
      <h3 :title="$t('tiles.productionRunCompletion')" class="primary-title">
        {{ $t("tiles.productionRunCompletion") }}
        <v-icon v-if="!isTileAuthorized()">mdi-lock</v-icon>
      </h3>

      <fragment>
        <h2 class="primary-value">{{ isLiveData ? primaryValue : dashChar }}</h2>
        <p v-if="isLiveData" class="value-sub-text__unit count">
          {{ secondaryValue }}
          {{ unitName }}
        </p>
        <section class="status-details">
          <dl>
            <dt>{{ $t("tiles.plannedQuantity") }}</dt>
            <dd>
              {{ isLiveData ? plannedQuantity : dashChar }}
            </dd>
          </dl>
        </section>
      </fragment>
      <!-- if !isProductionInProgress -->
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ProductionUnitService from "@/components/productionunit/ProductionUnitService";
import GroupedTileSelectorMenu from "@/components/dashboard/tiles/GroupedTileSelectorMenu";
import TileBase from "@/components/dashboard/tiles/TileBase";
import Tiles from "@/components/Tiles";
import Helpers, { dash } from "@/helpers";

export default {
  name: "TileProduction",
  extends: TileBase,
  inheritAttrs: false,
  components: { GroupedTileSelectorMenu },
  computed: {
    ...mapGetters("dashboard", ["currentProductionRun", "productionUnitConvertedUnit"]),
    ...mapGetters("navigation", ["isLiveData"]),
    ...mapGetters("packages", ["activePuHasRequiredFeature"]),
    dashChar() {
      return dash;
    },
    isTileDisabled() {
      return !this.isTileAuthorized();
    },
    isPlannedQuantity() {
      return (
        this.currentProductionRun !== null &&
        this.currentProductionRun.planned_quantity !== null &&
        this.currentProductionRun.planned_quantity > 0
      );
    },
    primaryValue() {
      const currentPlannedQuantity = this.currentProductionRun ? this.currentProductionRun.planned_quantity : 0;
      if (currentPlannedQuantity && currentPlannedQuantity > 0) {
        return Helpers.round(100 * (this.currentQuantity / currentPlannedQuantity), 1) + "%";
      } else {
        return dash;
      }
    },
    secondaryValue() {
      if (this.currentQuantity) {
        return Helpers.round(this.currentQuantity, 0);
      } else {
        return dash;
      }
    },
    unitName() {
      if (this.productionUnitConvertedUnit && this.isTileAuthorized()) {
        return ProductionUnitService.getUnitName(this.productionUnitConvertedUnit);
      } else {
        return "";
      }
    },
    currentQuantity() {
      return this.currentProductionRun
        ? this.currentProductionRun.quantity - (this.currentProductionRun?.reject_quantity ?? 0)
        : 0;
    },
    plannedQuantity() {
      return this.currentProductionRun?.planned_quantity ? this.currentProductionRun.planned_quantity : dash;
    },
  },
  methods: {
    isTileAuthorized() {
      return this.activePuHasRequiredFeature(Tiles.production.requiredFeature);
    },
  },
};
</script>

<style lang="scss">
.wrap {
  overflow-wrap: normal;
}
</style>
