<template>
  <v-card
    :wxid="$options.name"
    :class="[{ disabled: isTileDisabled }, targetStatusClass]"
    class="tile product-throughput status-pictogram"
    tag="section"
    sf="2"
  >
    <grouped-tile-selector-menu :tile-index="tileIndex" />
    <div class="tile-inner">
      <h3 :title="$t('tiles.productThroughput_unit', { unit: unitName })" class="primary-title">
        {{ $t("tiles.productThroughput_unit", { unit: unitName }) }}
        <v-icon v-if="!isTileAuthorized()">mdi-lock</v-icon>
      </h3>
      <h2 class="primary-value">{{ primaryValue }}</h2>
      <p class="value-sub-text__product-note">({{ $t("tiles.currentProductNote") }})</p>
      <section class="status-details">
        <dl>
          <dt>{{ targetType }}</dt>
          <dd>
            <v-icon small>mdi-target</v-icon>
            {{ targetValue }}
          </dd>
        </dl>
        <div class="pictogram" />
      </section>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ProductionUnitService from "@/components/productionunit/ProductionUnitService";
import GroupedTileSelectorMenu from "@/components/dashboard/tiles/GroupedTileSelectorMenu";
import TileBase from "@/components/dashboard/tiles/TileBase";
import Tiles from "@/components/Tiles";
import Helpers from "@/helpers";

export default {
  name: "TileProductThroughput",
  extends: TileBase,
  inheritAttrs: false,
  components: { GroupedTileSelectorMenu },
  computed: {
    ...mapGetters("dashboard", [
      "activeProductionUnitId",
      "productionUnitConvertedUnit",
      "currentProduct",
      "currentProductSku",
      "currentProductThroughput",
      "currentProductPUAssociations",
      "currentProductProductionObjective",
    ]),
    ...mapGetters("packages", ["activePuHasRequiredFeature"]),
    isTileDisabled() {
      return !this.isTileAuthorized();
    },
    unitName() {
      if (this.productionUnitConvertedUnit && this.isTileAuthorized()) {
        return " - " + ProductionUnitService.getUnitName(this.productionUnitConvertedUnit) + this.$t("common.perHour");
      } else {
        return "";
      }
    },
    primaryValue() {
      return Helpers.getRoundedValueOrDash(this.currentProductThroughput, this.isTileAuthorized());
    },
    target() {
      if (this.currentProductPUAssociations && this.isTileAuthorized()) {
        const objective = this.currentProductProductionObjective;
        if (objective) {
          return objective.value;
        }
      }
      return null;
    },
    targetValue() {
      return this.target ? this.target : this.$t("common.nA");
    },
    targetType() {
      return this.$t("dashboard.targetInfo.product");
    },
    targetStatusClass() {
      let statusClass;
      if (this.currentProductThroughput && this.target && this.target > 0) {
        const valueAsPercent = 100.0 * (this.currentProductThroughput / this.target);
        if (valueAsPercent >= 100.0) {
          statusClass = "success-state";
        } else if (valueAsPercent >= 90.0) {
          statusClass = "warning-state";
        } else {
          statusClass = "error-state";
        }
      }
      return statusClass;
    },
  },
  methods: {
    isTileAuthorized() {
      return this.activePuHasRequiredFeature(Tiles.currentProductThroughput.requiredFeature);
    },
  },
};
</script>
