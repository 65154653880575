<template>
  <v-card :wxid="$options.name" tag="section" class="wx-panel panel-production-timeline">
    <header class="panel-header">
      <div class="panel-title d-flex flex-column flex-md-row">
        <create-production-run-dialog
          :initial-product="currentProduct"
          :initial-work-order="currentWorkOrder"
          :initial-lot="currentLot"
          btn-css-class="mr-sm-3 mobile-squeezed-btn"
        />

        <div v-if="isMobilePhone" class="d-flex flex-column-gap mt-3 mb-2">
          <manual-rejects-entry
            v-if="isPuHasRejectPackage() && !isPresenter"
            btn-css-class="mr-sm-3 mobile-squeezed-btn"
          />

          <wx-btn-standard
            v-if="!selectedDowntime"
            @click="selectLastDowntime"
            :title="$t('dashboard.panelHeader.showJustificationPanel_hoverTitle')"
            :disabled="noDowntimesVisible"
            class="toggle-jutifications-btn mobile-squeezed-btn"
          >
            {{ $t("dashboard.panelHeader.toggleJustificationsBtn") }}
            <v-icon right>mdi-arrow-top-right-bottom-left</v-icon>
          </wx-btn-standard>
          <wx-btn-standard
            v-if="selectedDowntime"
            @click="unselectDowntime"
            :title="$t('dashboard.panelHeader.hideJustificationPanel_hoverTitle')"
            class="toggle-jutifications-btn mobile-squeezed-btn"
            color="primary"
          >
            {{ $t("dashboard.panelHeader.toggleJustificationsBtn") }}
            <v-icon right>mdi-close</v-icon>
          </wx-btn-standard>
        </div>
      </div>

      <manual-rejects-entry
        v-if="isPuHasRejectPackage() && !isMobilePhone && !isPresenter"
        :btn-css-class="showTimeline ? 'mx-sm-1 mr-sm-4' : null"
      />

      <wx-btn-standard
        v-if="!isMobilePhone && !selectedDowntime && !isPresenter && showTimeline"
        :title="$t('dashboard.panelHeader.showJustificationPanel_hoverTitle')"
        :disabled="noDowntimesVisible"
        @click="selectLastDowntime"
        class="toggle-jutifications-btn"
      >
        {{ $t("dashboard.panelHeader.toggleJustificationsBtn") }}
        <v-icon right>mdi-arrow-top-right-bottom-left</v-icon>
      </wx-btn-standard>
      <wx-btn-standard
        v-if="!isMobilePhone && selectedDowntime && !isPresenter"
        :title="$t('dashboard.panelHeader.hideJustificationPanel_hoverTitle')"
        @click="unselectDowntime"
        class="toggle-jutifications-btn"
        color="primary"
      >
        {{ $t("dashboard.panelHeader.toggleJustificationsBtn") }}
        <v-icon right>mdi-close</v-icon>
      </wx-btn-standard>

      <legend-oee-graph-dialog v-if="showTimeline" />

      <v-menu v-if="showTimeline">
        <template v-slot:activator="{ on, attrs }">
          <wx-btn-icon
            v-bind="attrs"
            v-on="on"
            :title="$t('dashboard.panelHeader.chooseWhichChartToDisplay_hoverTitle')"
            class="legend-btn"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </wx-btn-icon>
        </template>

        <v-list>
          <v-list-item v-for="(item, index) in graphOptions" :key="index" @click="selectedGraph = item.value">
            <v-list-item-title class="graphOptionMenu" :class="{ 'primary--text': selectedGraph == item.value }">
              {{ item.label }}
              <v-icon v-if="selectedGraph === item.value" class="selectedGraphIcon">mdi-check</v-icon>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </header>

    <fragment v-if="showTimeline">
      <timeline-markers />
      <timeline-production-blocks />
      <timeline-tics v-if="!isOEEGraph()" />
      <timeline-oee-graph v-if="isOEEGraph()" />
      <timeline-quantity-graph v-else class="mt-6" />
      <timeline-tics v-if="isOEEGraph()" />
    </fragment>
    <fragment v-else>
      <v-row>
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <wx-alert-query-message :text="$t('dashboard.timelineMessageForProductionRuns')" no-results-icon />
        </v-col>
      </v-row>
    </fragment>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CreateProductionRunDialog from "@/components/dashboard/productionRun/CreateProductionRunDialog";
import ManualRejectsEntry from "@/components/dashboard/ManualRejectsEntry";
import WxBtnStandard from "@/components/ui/WxBtnStandard";
import LegendOeeGraphDialog from "@/components/dashboard/dialogs/LegendOeeGraphDialog.vue";
import TimelineMarkers from "@/components/dashboard/TimelineMarkers";
import TimelineProductionBlocks from "@/components/dashboard/TimelineProductionBlocks";
import TimelineOeeGraph from "@/components/dashboard/TimelineOeeGraph";
import TimelineQuantityGraph from "@/components/dashboard/TimelineQuantityGraph";
import TimelineTics from "@/components/dashboard/TimelineTics.vue";
import PackageFeatures from "@/components/PackageFeatures";
import WxBtnIcon from "@/components/ui/WxBtnIcon";
import WxAlertQueryMessage from "@/components/ui/WxAlertQueryMessage.vue";

export default {
  name: "PanelProductionTimeline",
  props: {
    showTimeline: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    WxAlertQueryMessage,
    CreateProductionRunDialog,
    ManualRejectsEntry,
    LegendOeeGraphDialog,
    TimelineMarkers,
    TimelineProductionBlocks,
    TimelineOeeGraph,
    TimelineTics,
    TimelineQuantityGraph,
    WxBtnStandard,
    WxBtnIcon,
  },
  data() {
    return {
      selectedMarkerId: "",
      downtimeToggleValue: 0,
      selectedGraph: "oee",
    };
  },
  watch: {
    selectedDowntime() {
      this.downtimeToggleValue = this.selectedDowntime ? 1 : 0;
    },
    selectedGraph() {
      this.updateSelectedGraph(this.selectedGraph);
    },
    activeProductionUnitId() {
      this.selectedGraph = this.graphSelection;
    },
  },
  computed: {
    ...mapGetters("dashboard", [
      "currentProduct",
      "currentWorkOrder",
      "currentLot",
      "oeeGraph",
      "downtimes",
      "selectedDowntime",
      "productMarkers",
      "graphSelection",
      "activeProductionUnitId",
    ]),
    ...mapGetters("user", ["isPresenter"]),
    ...mapGetters("packages", ["activePuHasRequiredFeature"]),
    currentProductFullName() {
      if (this.currentProduct) {
        return this.currentProduct.name
          ? this.currentProduct.name + " — " + this.currentProduct.sku
          : this.currentProduct.sku;
      } else {
        return "—";
      }
    },
    noDowntimesVisible() {
      return this.downtimes.length === 0;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    isMobilePhone() {
      const vbpName = this.$vuetify.breakpoint.name;
      let phoneBreakpoint = false;
      if (vbpName === "xs") {
        phoneBreakpoint = true;
      }
      return phoneBreakpoint;
    },
    graphOptions() {
      return [
        {
          label: this.$t("dashboard.panelHeader.oeeGraph"),
          value: "oee",
        },
        {
          label: this.$t("dashboard.panelHeader.quantityGraph"),
          value: "quantity",
        },
      ];
    },
  },
  methods: {
    ...mapActions("dashboard", ["unselectDowntime", "selectLastDowntime"]),
    ...mapActions("user", ["updateSelectedGraph"]),
    isPuHasRejectPackage() {
      return this.activePuHasRequiredFeature(PackageFeatures.reject);
    },
    isOEEGraph() {
      return this.selectedGraph === "oee";
    },
  },
  mounted() {
    this.selectedGraph = this.graphSelection;
  },
};
</script>

<style lang="scss" scoped>
.wx-panel.v-card {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius-lg); // Tiles border-radius
  padding-bottom: 35px;
}

.panel-header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;

  .panel-title {
    margin-right: auto;

    ::v-deep .v-btn {
      &.product-selected,
      &.no-product-selected {
        .v-btn__content {
          @media (max-width: $wx-max-width-phone-vertical) {
            max-width: 130px;
          }
        }
      }
      &.product-selected {
        .v-btn__content {
          @media (min-width: $wx-min-width-phone-vertical) {
            max-width: 300px;
          }
          @media (min-width: $wx-min-width-tablet-vertical) {
            max-width: 420px;
          }
          @media ($wx-md-min) {
            max-width: 735px;
          }
        }
      }
    }
  }

  // Single buttons
  ::v-deep > .v-btn {
    &.legend-btn {
      margin-left: 4px;

      @media (min-width: $wx-min-width-tablet-vertical) {
        margin-left: var(--btn-inline-margin);
      }
    }
  }

  .toggle {
    > :first-child {
      border-radius: var(--border-radius-form-elements) 0 0 var(--border-radius-form-elements);
    }
    > :last-child {
      border-radius: 0 var(--border-radius-form-elements) var(--border-radius-form-elements) 0;
    }

    // when active UX = disable
    .v-btn.v-btn--active {
      pointer-events: none;
      cursor: auto;
    }
  }
}
.truncated {
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 200px;
}

.mobile-squeezed-btn {
  ::v-deep &.v-btn {
    flex-grow: 1;
    flex-shrink: 1;
    @media (max-width: $wx-breakpoint-phone-vertical) {
      padding-inline: 5px !important;
    }
  }
}

.graphOptionMenu {
  width: 150px;
}
.selectedGraphIcon {
  color: inherit;
  float: right;
}
</style>
