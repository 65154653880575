var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"timeline-blocks-container",attrs:{"wxid":_vm.$options.name}},[_vm._l((_vm.shiftMarkers),function(marker,index){return [_c('div',{key:`shift-marker-${index}`,staticClass:"shift-marker",style:({
        left: _vm.leftPosition(marker.start),
      })})]}),_vm._l((_vm.timeline.blocks),function(block,index){return [(_vm.isBlockDown(block.state) && _vm.isJustifiableDowntime(block) && !_vm.isPresenter)?_c('button',{key:`timeline-block-${index}`,staticClass:"block",class:_vm.clickableTimelineBlockCssClass(block),style:({
        left: _vm.leftPosition(block.start),
        width: _vm.blockWidth(block.duration),
      }),on:{"click":function($event){return _vm.selectDowntime(block.downtime.id)}}},[_vm._l((block.sub_blocks),function(sub_block,indexSubBlock){return [_c('span',{key:`timeline-subblock-${indexSubBlock}-${sub_block.duration}`,class:sub_block.state,style:({
            width: _vm.subBlockWidth(block.duration, sub_block.duration),
          })})]})],2):(_vm.isBlockDown(block.state) && !_vm.isJustifiableDowntime(block) && !_vm.isPresenter)?_c('v-tooltip',{key:`timeline-unclick-block-${index}`,attrs:{"right":"","allow-overflow":"","offset-overflow":"","disabled":!_vm.showUnclickableBlockTooltip(block)},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(_vm.hasSubBlocks(block))?_c('button',_vm._g({key:`timeline-block-${index}`,staticClass:"block defaultMouseCursor",class:_vm.clickableTimelineBlockCssClass(block),style:({
            left: _vm.leftPosition(block.start),
            width: _vm.blockWidth(block.duration),
          })},on),[_vm._l((block.sub_blocks),function(sub_block,indexSubBlock){return [_c('span',{key:`timeline-subblock-${indexSubBlock}-${sub_block.duration}`,class:sub_block.state,style:({
                width: _vm.subBlockWidth(block.duration, sub_block.duration),
              })})]})],2):_c('div',_vm._g({staticClass:"block",class:_vm.unclickableTimelineBlockCssClass(block, index),style:({
            left: _vm.leftPosition(block.start),
            width: _vm.blockWidth(block.duration),
          })},on))]}}],null,true)},[_c('div',{staticClass:"wx-info-window"},[(_vm.hasSubBlocks(block))?_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t("timeline.blocks.noJustificationRequiredMinutes", { delay: _vm.formattedJustificationDelay }))+". "+_vm._s(_vm.$t("timeline.blocks.withSubBlockButNoJustificationRequiredMinutes"))+" ")]):_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t("timeline.blocks.noJustificationRequiredMinutes", { delay: _vm.formattedJustificationDelay }))+" ")])])]):_c('button',{key:`timeline-block-${index}`,staticClass:"block",class:_vm.unclickableTimelineBlockCssClass(block, index),style:({
        left: _vm.leftPosition(block.start),
        width: _vm.blockWidth(block.duration),
      }),attrs:{"disabled":""}},[_vm._l((block.sub_blocks),function(sub_block,indexSubBlock){return [_c('span',{key:`timeline-subblock-${indexSubBlock}-${sub_block.duration}`,class:sub_block.state,style:({
            width: _vm.subBlockWidth(block.duration, sub_block.duration),
          })})]})],2)]}),(!_vm.coverageIsOneHourOrLess)?_c('div',{staticClass:"progress-marker",style:({ left: _vm.periodProgressPercentage })},[_c('span',{staticClass:"d-sr-only"},[_vm._v(_vm._s(_vm.periodProgressPercentageFloored))])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }