<!-- Tics are horizontal ruler marks -->
<template>
  <section class="timeline-tics-container">
    <div
      v-for="(tic, index) in ticAmount"
      :key="`timeline-tic-${index}`"
      class="tic"
      :class="isTicInfo(index)"
      :style="{ left: ticPosition(index) }"
    >
      <span v-if="isTicInfo(index)" class="tic-number wx-typo-xs">{{ ticInfoValue(index) }}</span>
      <span v-if="isTicInfo(index) && ticInfoValue(index) === 0" class="tic-date wx-typo-xs text--disabled pt-2">{{
        ticDate(index)
      }}</span>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TimelineTics",
  data() {
    return {
      dateAbbreviation: "",
    };
  },
  watch: {
    timeline() {
      this.dateAbbreviation = this.getDateAbbreviation();
    },
    activeFactory() {
      this.dateAbbreviation = this.getDateAbbreviation();
    },
    isTimeRangeReady() {
      this.dateAbbreviation = this.getDateAbbreviation();
    },
    timeFrame() {
      this.dateAbbreviation = this.getDateAbbreviation();
    },
  },
  computed: {
    ...mapGetters("dashboard", ["timeline"]),
    ...mapGetters("navigation", [
      "activeFactory",
      "timeFrame",
      "isTimeRangeReady",
      "startDateTime",
      "endDateTime",
      "durationMillis",
      "offsetFromMidnightMillis",
    ]),
    durationSeconds() {
      return this.durationMillis ? parseInt(this.durationMillis / 1000) : 0;
    },
    offsetFromMidnightSeconds() {
      return this.offsetFromMidnightMillis ? parseInt(this.offsetFromMidnightMillis / 1000) : 0;
    },
    ticInterval() {
      return 3600 / this.ticPerHour;
    },
    ticAmount() {
      return Math.ceil(this.durationSeconds / this.ticInterval);
    },
    ticOffset() {
      return (this.offsetFromMidnightSeconds % 3600) % this.ticInterval;
    },
    ticPerHour() {
      if (this.durationSeconds <= 3600) {
        return 60;
      } else {
        return 6;
      }
    },
    coverageIsOneHourOrLess() {
      return this.durationSeconds <= 3600;
    },
  },
  methods: {
    ticPosition(ticNumber) {
      return ((ticNumber * this.ticInterval - this.ticOffset) / this.durationSeconds) * 100 + "%";
    },
    isTicInfo(ticIndex) {
      const moduloValue = this.coverageIsOneHourOrLess ? 900 : 3600;
      if ((ticIndex * this.ticInterval + this.offsetFromMidnightSeconds) % moduloValue === this.ticOffset) {
        return "tic-info";
      }
      return false;
    },
    getTimeDecimalValue(ticIndex) {
      return (ticIndex * this.ticInterval + this.offsetFromMidnightSeconds - this.ticOffset) / 3600;
    },
    ticInfoValue(ticIndex) {
      const timeDecimalValue = this.getTimeDecimalValue(ticIndex);
      const hours = Math.floor(timeDecimalValue) % 24;
      if (this.coverageIsOneHourOrLess) {
        const minutes = 60 * (timeDecimalValue % 1);
        return hours + ":" + (minutes < 10 ? "0" : "") + minutes;
      } else {
        return hours;
      }
    },
    ticDate(ticIndex) {
      if (this.endDateTime.toMillis() - this.startDateTime.toMillis() > 86400000) {
        // 24 hours
        const timeDecimalValue = this.getTimeDecimalValue(ticIndex);
        const factor = Math.floor(timeDecimalValue) / 24;
        return this.startDateTime
          .plus({ days: factor })
          .toJSDate()
          .toLocaleDateString("fr-CA");
      } else {
        return this.dateAbbreviation;
      }
    },
    getDateAbbreviation() {
      if (!this.activeFactory || !this.startDateTime || !this.endDateTime) return "";
      const startDate = this.startDateTime.toJSDate();
      const endDate = this.endDateTime.toJSDate();
      const dateLabel = this.activeFactory.productionDayMinutesFromMidnight === 0 ? startDate : endDate;
      return dateLabel.toLocaleDateString("fr-CA");
    },
  },
  mounted() {
    this.dateAbbreviation = this.getDateAbbreviation();
  },
};
</script>

<style lang="scss" scoped>
.timeline-tics-container {
  flex: 0 0 auto;
  position: relative;
  height: 24px;
  margin-top: 8px;

  .tic {
    position: absolute;
    top: 0;
    height: 3px;
    width: 1px;
    background-color: currentColor;

    &.tic-info {
      height: 6px;
    }

    > .tic-number {
      position: absolute;
      top: 9px;
      left: 1px;
      transform: translateX(-50%);
    }

    > .tic-date {
      top: 25px;
      position: absolute;
      white-space: nowrap;
    }
  }
}
</style>
