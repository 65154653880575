<template>
  <v-dialog v-model="modalDialog" :wxid="$options.name" max-width="var(--modal-window-narrow-width)">
    <template #activator="{ on }">
      <wx-btn-standard
        v-on="on"
        :wxid="$options.name"
        :title="$t('productionUnit.measureSources.exportButton_hint')"
        :class="activatorButtonCssClass"
        class="measures-export-dialog__activator-btn"
        large
      >
        <v-icon left>mdi-cloud-download-outline</v-icon>
        {{ getExportButtonText }}
      </wx-btn-standard>
    </template>

    <v-card :wxid="$options.name" class="wx-modal-dialog">
      <v-card-title tag="header" class="unbreak-word">
        <h2>{{ getWindowTitle }}</h2>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-row>
          <v-col cols="12" sm="7" class="field-col">
            <wx-simple-calendar-date-picker
              v-model="effectiveStartDate"
              :label="$t('dashboard.productionRun.effectiveStartDate')"
              :timezone="getTimezone()"
              :limit-past-number-of-days="limitPastNumberOfDays"
              :rules="[() => validateAndSetEffectiveDates()]"
              hide-details
            />
          </v-col>
          <v-col cols="12" sm="5" class="field-col">
            <wx-time-picker
              v-model="effectiveStartTime"
              :title="$t('dashboard.productionRun.effectiveStartTime')"
              :rules="[() => validateAndSetEffectiveDates()]"
              :show-prepend-icon="false"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="7" class="field-col">
            <wx-simple-calendar-date-picker
              v-model="effectiveEndDate"
              :label="$t('dashboard.productionRun.effectiveEndDate')"
              :timezone="getTimezone()"
              :rules="[() => validateAndSetEffectiveDates()]"
              :limit-past-number-of-days="limitPastNumberOfDays"
              hide-details
            />
          </v-col>
          <v-col cols="12" sm="5" class="field-col">
            <wx-time-picker
              v-model="effectiveEndTime"
              :title="$t('dashboard.productionRun.effectiveEndTime')"
              :rules="[() => validateAndSetEffectiveDates()]"
              :show-prepend-icon="false"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row v-if="dateErrorMessages == [] || !timeErrorMessages == []" class="error-messages-container">
          <v-col>
            <p class="error--text text-center mb-0">{{ timeErrorMessages.toString() }}</p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions tag="footer" class="flex-column">
        <!-- Loading animation above the buttons after Submit -->
        <div class="w-100 pb-1" style="min-height:40px;">
          <div v-if="exportInProgress" class="d-flex align-center">
            <v-progress-circular color="primary" :size="50" :width="7" indeterminate />
            <p class="ml-3 mb-0">
              {{ $t("common.inProgressNotification") }}
            </p>
          </div>
        </div>
        <div class="w-100 d-flex flex-button-column-gap">
          <wx-btn-standard
            v-show="!exportInProgress"
            @click="
              modalDialog = false;
              toDefaultTimeDate();
            "
            :title="$t('common.dialogFormCancelHoverTitle')"
            color="secondary"
            class="cancel-btn flex-grow-1"
            large
          >
            {{ $t("common.cancel") }}
          </wx-btn-standard>
          <wx-btn-standard
            :disabled="!validData"
            @click="fetchData()"
            :title="$t('productionUnit.measureSources.downloadButton_hint')"
            color="primary"
            class="submit-btn flex-grow-1"
            large
          >
            {{ getDownloadButtonText }}
            <v-icon right>mdi-cloud-download-outline</v-icon>
          </wx-btn-standard>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import WxBtnStandard from "@/components/ui/WxBtnStandard.vue";
import WxSimpleCalendarDatePicker from "@/components/ui/WxSimpleCalendarDatePicker.vue";
import WxTimePicker from "@/components/ui/WxTimePicker.vue";
import * as TimeUtils from "@/store/TimeUtils";
import { DateTime } from "luxon";
import moment from "moment-timezone";
import ErrorHandling from "@/components/ErrorHandling";
import { mapActions, mapGetters } from "vuex";
import MeasureExportService from "@/components/productionunit/MeasureExportService.js";

export default {
  name: "MeasuresExportDialog",
  components: {
    WxBtnStandard,
    WxSimpleCalendarDatePicker,
    WxTimePicker,
  },
  props: {
    measureType: {
      type: String,
      required: true,
      validator: function(value) {
        // The value must match one of these strings
        return ["puevents", "giveawaysamples"].includes(value);
      },
    },
    activatorButtonCssClass: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      modalDialog: false,
      effectiveStartDate: "",
      effectiveStartTime: "",
      effectiveEndDate: this.getDefaultEffectiveDate(),
      effectiveEndTime: this.getDefaultEffectiveTime(),
      exportInProgress: false,

      exportStart: "",
      exportEnd: "",

      dateErrorMessages: [],
      timeErrorMessages: [],

      validData: false,
    };
  },
  computed: {
    ...mapGetters("navigation", ["activeFactory", "activeFactoryProductionUnits"]),
    ...mapGetters("user", ["language", "worximityAdminAllowedCoverageInMonths"]),
    maxPastDays() {
      return TimeUtils.getNumberOfDaysInLastNMonths(this.worximityAdminAllowedCoverageInMonths);
    },
    currentProductionUnitId() {
      return this.$route.params.id;
    },
    getExportButtonText() {
      if (this.measureType === "puevents") {
        return this.$t("productionUnit.measureSources.measures.exportBtn");
      } else {
        return this.$t("productionUnit.measureSources.giveawaySamples.exportBtn");
      }
    },
    limitPastNumberOfDays() {
      if (this.measureType === "puevents") {
        return 7;
      } else {
        return 365;
      }
    },
    getWindowTitle() {
      if (this.measureType === "puevents") {
        return this.$t("productionUnit.measureSources.measures.windowTitle");
      } else {
        return this.$t("productionUnit.measureSources.giveawaySamples.windowTitle");
      }
    },
    getDownloadButtonText() {
      if (this.measureType === "puevents") {
        return this.$t("productionUnit.measureSources.measures.downloadBtn");
      } else {
        return this.$t("productionUnit.measureSources.giveawaySamples.downloadBtn");
      }
    },
  },
  methods: {
    ...mapActions("operation", ["showOperationError"]),
    validateAndSetEffectiveDates() {
      this.resetDateValidations();

      let startDateAsDateTime = null;
      let endDateAsDateTime = null;

      let startTimeAsDateTime = null;
      let endTimeAsDateTime = null;

      //start Date validation and transformation
      if (this.effectiveStartDate) {
        startDateAsDateTime = DateTime.fromFormat(this.effectiveStartDate, TimeUtils.DATE_FORMAT, {
          zone: this.getTimezone(),
          setZone: true,
        });
        if (!startDateAsDateTime.isValid) {
          this.dateErrorMessages = [this.$t("dashboard.productionRun.errors.invalidDate")];
          this.validData = false;
          return false;
        }
      } else {
        this.dateErrorMessages = [this.$t("dashboard.productionRun.errors.dateMissing")];
        this.validData = false;
        return false;
      }
      //Start time validation and transformation
      if (this.effectiveStartTime) {
        startTimeAsDateTime = DateTime.fromFormat(this.effectiveStartTime, TimeUtils.TIME_FORMAT, {
          zone: this.getTimezone(),
          setZone: true,
        });
        if (!startTimeAsDateTime.isValid) {
          this.timeErrorMessages = [this.$t("dashboard.productionRun.errors.invalidTime")];
          this.validData = false;
          return false;
        }
      } else {
        this.timeErrorMessages = [this.$t("dashboard.productionRun.errors.timeMissing")];
        this.validData = false;
        return false;
      }

      //End Date validation and transformation
      if (this.effectiveEndDate) {
        endDateAsDateTime = DateTime.fromFormat(this.effectiveEndDate, TimeUtils.DATE_FORMAT);
        if (!endDateAsDateTime.isValid) {
          this.dateErrorMessages = [this.$t("dashboard.productionRun.errors.invalidDate")];
          this.validData = false;
          return false;
        }
      } else {
        this.dateErrorMessages = [this.$t("dashboard.productionRun.errors.dateMissing")];
        this.validData = false;
        return false;
      }
      //End time validation and transformation
      if (this.effectiveEndTime) {
        endTimeAsDateTime = DateTime.fromFormat(this.effectiveEndTime, TimeUtils.TIME_FORMAT);
        if (!endTimeAsDateTime.isValid) {
          this.timeErrorMessages = [this.$t("dashboard.productionRun.errors.invalidTime")];
          this.validData = false;
          return false;
        }
      } else {
        this.timeErrorMessages = [this.$t("dashboard.productionRun.errors.timeMissing")];
        this.validData = false;
        return false;
      }

      //exportStart
      this.exportStart = startDateAsDateTime.set({
        hour: startTimeAsDateTime.hour,
        minute: startTimeAsDateTime.minute,
        second: 0,
        millisecond: 0,
      });

      //exportEnd
      this.exportEnd = endDateAsDateTime.set({
        hour: endTimeAsDateTime.hour,
        minute: endTimeAsDateTime.minute,
        second: 0,
        millisecond: 0,
      });

      //Check if dates are in the future
      const now = DateTime.now().set({ second: 0, millisecond: 0 });
      if (this.exportStart > now) {
        this.dateErrorMessages = [this.$t("dashboard.productionRun.errors.dateInFuture")];
        this.timeErrorMessages = [this.$t("dashboard.productionRun.errors.dateInFuture")];
        this.validData = false;
        return false;
      }
      if (this.exportEnd > now) {
        this.dateErrorMessages = [this.$t("dashboard.productionRun.errors.dateInFuture")];
        this.timeErrorMessages = [this.$t("dashboard.productionRun.errors.dateInFuture")];
        this.validData = false;
        return false;
      }

      //Check if start time is after end time
      if (this.exportStart > this.exportEnd) {
        this.dateErrorMessages = [this.$t("dashboard.productionRun.errors.startAfterEnd")];
        this.timeErrorMessages = [this.$t("dashboard.productionRun.errors.startAfterEnd")];
        this.validData = false;
        return false;
      }
      this.validData = true;
      return true;
    },

    toDefaultTimeDate() {
      this.effectiveStartDate = "";
      this.effectiveStartTime = "";
      this.effectiveEndDate = this.getDefaultEffectiveDate();
      this.effectiveEndTime = this.getDefaultEffectiveTime();
    },
    resetDateValidations() {
      this.dateErrorMessages = [];
      this.timeErrorMessages = [];
      this.validationError = false;
      this.validationErrorMessage = "";
    },
    getDefaultEffectiveDate() {
      return TimeUtils.getTodayDate(this.getTimezone());
    },
    getDefaultEffectiveTime() {
      return TimeUtils.getCurrentTime(this.getTimezone());
    },
    getTimezone() {
      if (this.activeFactory && this.activeFactory.timezone) {
        return this.activeFactory.timezone;
      } else {
        const zone = moment.tz.guess(true);
        return zone === null || zone === undefined ? "America/Montreal" : zone;
      }
    },
    fetchData() {
      this.exportInProgress = true;

      if (this.measureType === "puevents") {
        MeasureExportService.fetchMeasureData(
          this.currentProductionUnitId,
          this.exportStart.toISO(),
          this.exportEnd.toISO(),
        )
          .then((httpResponse) => this.handleDataExportResponse(httpResponse))
          .catch((httpResponse) => this.handleDataExportError(httpResponse));
      } else {
        MeasureExportService.fetchGiveawaySampleData(
          this.currentProductionUnitId,
          this.exportStart.toISO(),
          this.exportEnd.toISO(),
        )
          .then((httpResponse) => this.handleDataExportResponse(httpResponse))
          .catch((httpResponse) => this.handleDataExportError(httpResponse));
      }
    },
    handleDataExportResponse(httpResponse) {
      let fileName = this.extractFileName(httpResponse.headers["content-disposition"]);
      let file = window.URL.createObjectURL(new Blob([httpResponse.data]));
      let docUrl = document.createElement("a");
      docUrl.href = file;
      docUrl.setAttribute("download", fileName);
      document.body.appendChild(docUrl);
      docUrl.click();
      document.body.removeChild(docUrl);
      this.exportInProgress = false;
      this.modalDialog = false;
    },
    handleDataExportError(httpResponse) {
      this.exportInProgress = false;
      this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
    },
    extractFileName(disposition) {
      return disposition !== null && disposition !== undefined
        ? disposition.replace('attachment; filename="', "").replace('"', "")
        : "Export.xlsx";
    },
    getErrorMessage(code) {
      return this.$t("common.errors.default", { code: code });
    },
  },
};
</script>
<style lang="scss" scoped>
.wx-modal-dialog {
  &.v-card {
    &.v-sheet {
      position: relative;
      max-height: 90vh;
      max-height: 90svh;
      padding: var(--dialog-padding);
      background-color: var(--color-element-layer3);
    }
  }

  // BEM
  .v-card {
    &__title {
      position: sticky;
      z-index: 1;
      top: 0;
      padding-top: 0;
      padding-bottom: var(--dialog-padding);
      background-color: var(--color-element-layer3);
    }
    &__text {
      position: relative;
      z-index: 0;
    }
  }
}
</style>
