<template>
  <section :wxid="$options.name" class="quantity-graph-container">
    <figure>
      <wx-chart-bar-graph
        css-classes="canvas-container"
        :chart-data="getChartData()"
        :chart-options="getChartOptions()"
      />
    </figure>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import WxChartBarGraph from "@/components/ui/WxChartBarGraph.vue";
import StyleValues from "@/styles/_values.scss";
import * as TimeUtils from "@/store/TimeUtils";

export default {
  name: "TimelineQuantityGraph",
  components: {
    WxChartBarGraph,
  },
  computed: {
    ...mapGetters("dashboard", ["quantityGraph"]),
    ...mapGetters("navigation", ["activeFactory", "durationMillis"]),
    ...mapGetters("user", ["theme"]),
  },
  methods: {
    getChartOptions() {
      return {
        borderRadius: 3,
        animation: {
          duration: 0,
        },
        title: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            // display: false,
            stacked: true,
            grid: {
              display: false,
            },
            ticks: { color: this.theme === "light" ? "black" : "white" },
          },
          y: {
            min: 0,
            display: true,
            stacked: true,
            grid: {
              display: false,
            },
            beginAtZero: true,
            ticks: { color: this.theme === "light" ? "black" : "white" },
          },
        },
        plugins: {
          // Required to prevent Maximum call stack exceeded error
          annotation: {
            annotations: [],
          },
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            displayColors: false,
            backgroundColor:
              this.theme === "light"
                ? StyleValues.color_baseBackground_themeDark
                : StyleValues.color_baseBackground_themeLight,
            bodyFont: { size: 14, weight: "bold" },
            titleFont: "{weight: 'normal'}",
            titleColor: this.theme === "light" ? StyleValues.color_text_themeDark : StyleValues.color_text_themeLight,
            bodyColor: this.theme === "light" ? StyleValues.color_text_themeDark : StyleValues.color_text_themeLight,
            footerFont: "{weight: 'normal'}",
            footerColor: this.theme === "light" ? StyleValues.color_text_themeDark : StyleValues.color_text_themeLight,
            callbacks: {
              title: function title(tooltipItems) {
                let tooltipItem = tooltipItems != null && tooltipItems instanceof Array ? tooltipItems[0] : null;
                if (tooltipItem) {
                  let tooltipSKU = tooltipItem.dataset.label !== "null" ? tooltipItem.dataset.label : "No Product";
                  return "SKU: " + tooltipSKU;
                } else {
                  return "";
                }
              },
              label: function label(tooltipItem) {
                return tooltipItem.dataset.data[tooltipItem.dataIndex] + " " + tooltipItem.dataset.unit;
              },
              footer: function footer(tooltipItems) {
                let tooltipItem = tooltipItems != null && tooltipItems instanceof Array ? tooltipItems[0] : null;
                if (tooltipItem) {
                  let dataset = tooltipItem.dataset;
                  if (dataset) {
                    const point = dataset.times[tooltipItem.dataIndex];
                    if (point) {
                      return point;
                    }
                  }
                } else {
                  return "";
                }
              },
            },
          },
        },
      };
    },
    findDatesNotInSecondArray(firstArray, secondArray) {
      // Create a Set of strings representing the objects in the second array
      const secondArraySet = new Set(secondArray.map((obj) => `${obj.start_time}-${obj.end_time}`));

      // Find objects in the first array that are not in the second array
      const missingObjects = firstArray.filter((obj) => {
        const key = `${obj.start_time}-${obj.end_time}`;
        return !secondArraySet.has(key);
      });

      let missingObjectsWithNullQty = missingObjects.map((obj) => {
        return {
          ...obj,
          sku: null,
          quantity: null,
          unit: null,
        };
      });

      // Pad the second array with missing objects
      const paddedSecondArray = secondArray.concat(missingObjectsWithNullQty);

      return paddedSecondArray.sort((a, b) => a.start_time - b.start_time);
    },
    getChartData() {
      let datasets = Object.entries(this.quantityGraph.quantityData).map((pair, index) => {
        let sku = pair[0];
        let skuQuantities = pair[1];
        let newValues = this.findDatesNotInSecondArray(this.quantityGraph.labels, skuQuantities);
        let times = this.formatTimeLabels(newValues, true);
        return {
          categoryPercentage: 0.7,
          barPercentage: 0.5,
          backgroundColor: this.getLineColor(index),
          label: sku,
          data: newValues.map((x) => x.quantity),
          unit: skuQuantities[0].unit,
          times: times,
        };
      });

      let labels = this.formatTimeLabels(this.quantityGraph.labels, false);

      return {
        labels: labels,
        datasets: datasets,
      };
    },
    formatTimeLabels(array, includeEndTime) {
      if (!this.isCoverageOver24Hours()) {
        return array.map((x) => this.formatTime(x.start_time, x.end_time, this.activeFactory.timezone, includeEndTime));
      } else {
        return array.map((x) =>
          this.formatDateAndTime(x.start_time, x.end_time, this.activeFactory.timezone, includeEndTime),
        );
      }
    },
    formatDateAndTime(startTime, endTime, timezone, includeEndTime) {
      let start = `${TimeUtils.toDateTimeNoTimezone(startTime, timezone)}`;
      let end = includeEndTime ? ` - ${TimeUtils.toDateTimeNoTimezone(endTime, timezone)}` : "";
      return start + end;
    },
    formatTime(startTime, endTime, timezone, includeEndTime) {
      let start = `${TimeUtils.toTimeHHMM(startTime, timezone)}`;
      let end = includeEndTime ? ` - ${TimeUtils.toTimeHHMM(endTime, timezone)}` : "";
      return start + end;
    },
    getLineColor(index) {
      if (this.theme === "dark") {
        return StyleValues[`wxData_lineColor${(index % StyleValues.wxData_colorsCount) + 1}Dark`];
      } else {
        return StyleValues[`wxData_lineColor${(index % StyleValues.wxData_colorsCount) + 1}Light`];
      }
    },
    isCoverageOver24Hours() {
      return this.durationMillis > 86400000;
    },
  },
};
</script>

<style lang="scss" scoped>
.quantity-graph-container {
  $containerDefaultHeight: 40px;

  flex: 1 0 auto;
  position: relative;
  overflow: hidden;
  height: ($containerDefaultHeight * 2);
  margin-top: 10px;

  @media ($wx-isNotMobile) {
    height: $containerDefaultHeight;
  }

  .canvas-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;

    ::v-deep canvas {
      height: 100%;
      width: 100%;
    }
  }
}
</style>
