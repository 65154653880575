<template>
  <tag-selector v-if="showTagSelector" :active-tag="activeTag" clearable @update="onTagUpdate" />
</template>

<script>
import TagSelector from "@/components/ui/tagselector/TagSelector.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "DashboardTagSelector",
  components: { TagSelector },
  data() {
    return {
      activeTag: null,
    };
  },
  watch: {
    activeProductionUnitTags() {
      this.activeTag = this.activeProductionUnitTags.length > 0 ? this.activeProductionUnitTags[0] : null;
    },
  },
  computed: {
    ...mapGetters("dashboard", ["activeProductionUnitTags"]),
    ...mapGetters("navigation", ["factoryTags", "isLiveData"]),
    showTagSelector() {
      return this.factoryTags.length > 0 && this.isLiveData;
    },
  },
  methods: {
    ...mapActions("dashboard", ["setActiveProductionUnitTag", "removeActiveProductionUnitTag"]),
    onTagUpdate(newTagId) {
      if (newTagId) {
        this.setActiveProductionUnitTag(newTagId);
      } else {
        this.removeActiveProductionUnitTag(this.activeTag.id);
      }
    },
  },
};
</script>
